import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { gql, useMutation, makeReference } from '@apollo/client';
import { useRouter } from 'next/router';
import { CREATE_TREATMENT } from 'graphql/philing';
import { pixelEvent } from 'utils/pixel';
// import { pixelEvent } from '@helper/pixel';

import { setPatientInfo } from 'modules/form/form.actions';

export default function useApplyTreatment({ onComplete, onError }) {
  const router = useRouter();
  const dispatch = useDispatch();

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const options = {
    variables: {},
    onCompleted: (response) => {
      const { id } = response.createTreatment;

      if (onComplete) {
        onComplete(id);
      }

      // form 초기화 하고
      // 첫화면으로 이동하기
      // dispatch(
      //   setPatientInfo({
      //     key: 'PHILING_FORM',
      //     info: {
      //       treatmentId,
      //     },
      //   })
      // );

      // router.push(`/philing/${symptomId}/payments`);

      // if next question exists
      // if (response.submitQuestionAnswer.medicalQuestion) {
      //   onComplete(response.submitQuestionAnswer.medicalQuestion.order);
      //   return;
      // }

      // // if not : medicalQuestion: null
      // // nextStep
      // if (user) {
      //   // submitQuestionAnswer
      // } else {
      //   // move to login page
      // }
    },
    onError: (error) => {
      onError(error);
    },
  };

  const [mutate, { data, loading: mutationLoading, error: mutationError }] = useMutation(
    CREATE_TREATMENT,
    options
  );

  const applyTreat = useCallback(
    (SubmitPatientInformationInput) => {
      return mutate({
        variables: {
          input: SubmitPatientInformationInput,
        },
      });
    },
    [mutate]
  );

  return [applyTreat];
}
