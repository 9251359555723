/**
 *
 * @param string
 * @param elementCallback
 * @returns {*}
 * @description 튀어쓰기($n)를 파싱하여 br태그로 치환반환
 * @usage {convertBreakLine(msg[process][type], (line, idx) => <React.Fragment key={idx}>{line}<br /></React.Fragment>)}
 */
export const convertBreakLine = (string, elementCallback) =>
  string.split('$n').map((line, idx) => elementCallback(line, idx));
// string.split('$n').map((line, idx) => <React.Fragment key={idx}>{line}<br /></React.Fragment>)

/**
 *
 * @param string
 * @param elementCallback
 * @returns {*}
 * @description 볼드($b)를 파싱하여 b태그로 치환반환
 * @usage {convertBreakLine(msg[process][type], (line, idx) => <React.Fragment key={idx}>{line}<b /></React.Fragment>)}
 */
export const convertBoldLine = (string, elementCallback) =>
  string.split('$b').map((line, idx) => elementCallback(line, idx));
// string.split('$n').map((line, idx) => <React.Fragment key={idx}>{line}<br /></React.Fragment>)

export const convertStyleLine = (string, elementCallback) =>
  string.split('$s').map((line, idx) => elementCallback(line, idx));

/**
 *
 * @param {*} string
 * @param {*} elementCallback
 */
export const convertOfferPriceRect = (string, elementCallback) =>
  string.split('$pr').map((line, idx) => elementCallback(line, idx));
// string.split('$n').map((line, idx) => <React.Fragment key={idx}>{line}<br /></React.Fragment>)

export const convertSocialName = (string) =>
  ({ kakao: '카카오톡', naver: '네이버', facebook: '페이스북' }[string]);

export const convertPhoneNumberFormat = (phoneNumber) =>
  phoneNumber
    .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, '$1-$2-$3')
    .replace('--', '-');
