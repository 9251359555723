import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export default function useCountDown(endOfOffer) {
  const [countDown, setCountDown] = useState({ day: 0, hour: 0, minute: 0 });

  useEffect(() => {
    runCountDown();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      runCountDown();
    }, 1000 * 60);

    return () => {
      console.log('clear countdown interval');
      clearInterval(intervalId);
    };
  }, []);

  const runCountDown = () => {
    const now = dayjs();
    const endOfDay = now.endOf('day');
    const endOfMinute = now.endOf('minute');
    // offer endDate
    const offerEnd = dayjs(endOfOffer);

    const remainMinutes = 59 - now.get('minute');
    setCountDown({
      day: Math.abs(now.diff(offerEnd, 'day')),
      hour: Math.abs(now.diff(endOfDay, 'hour')),
      minute: remainMinutes,
    });
  };

  return { day: countDown.day, hour: countDown.hour, minute: countDown.minute };
}
