import React, { useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';
import * as Styled from './index.styles';
import { Button } from 'components/atoms/Button';
import { useUser } from 'hooks/useUser';
import Skeleton from 'react-loading-skeleton';
import { useMutation, useQuery } from '@apollo/client';
import { initialState, actionTypes, reducer } from './index.reducer';
import USER_QUERY from 'graphql/user';
import { PrevHeader } from 'components';
import { getCookie } from 'utils/cookie';
import { webShare } from 'utils/webShare';
import { confirm } from 'components/molecules/Modal';
import * as ERROR_POPUP_TEXT from './rewardErrorCallback';

const tryUsePoint = async (userPointUseItem) => {
  const c = await confirm({
    // title: '타이틀이랍니다',
    text:
      `${userPointUseItem.itemPoint}P 를 사용하여\n기프티콘으로 교환하시겠어요?\n` +
      '교환 후에는 환불이나 취소가\n불가능합니다.',
    // link: {
    //   text: '링크랍니다',
    //   url: 'https://odoctor.co.kr'
    // }
    button: {
      cancel: true,
      ok: {
        text: '교환하기',
        callback: () => {
          // alert('교환')
        },
      },
    },
  });

  return c;
};

const successConfirm = async () => {
  const c = await confirm({
    // title: '타이틀이랍니다',
    text:
      '교환이 완료되었습니다.\n' +
      '교환하신 기프티콘은 메시지를 통해 가입된\n' +
      '연락처로 발송되오니 메시지를 확인해주세요.',
    // link: {
    //   text: '링크랍니다',
    //   url: 'https://odoctor.co.kr'
    // }
    button: {
      cancel: false,
      ok: {
        text: '확인',
        callback: () => {
          // alert('교환')
        },
      },
    },
  });

  return c;
};

const errorConfirm = async (text) => {
  const c = await confirm({
    ...text,
  });
  return c;
};

const SkeletonComponent = () => (
  <div>
    <Styled.ItemImageWrap>
      <Styled.ItemImage>
        <div className="skeleton-wrap">
          <Skeleton height="100%" />
        </div>
      </Styled.ItemImage>
    </Styled.ItemImageWrap>
    <Styled.ItemDescription>
      <Skeleton height="5.6rem" />
      <Skeleton width="50%" height="3.4rem" />
      <Styled.ItemNotice>
        <Skeleton height="5.6rem" />
      </Styled.ItemNotice>
    </Styled.ItemDescription>
  </div>
);

const Index = ({ match }) => {
  const router = useRouter();
  const user = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { rewardId: itemId } = router.query;

  if (!itemId) {
    return <div>잘못된 요청 페이지</div>;
  }

  const { data, loading, error } = useQuery(USER_QUERY.GET_REWARD_ITEM_DETAIL, {
    variables: { itemId },
  });

  const [updateUser, { loading: updateUserLoading, error: updateUserError }] = useMutation(
    USER_QUERY.UPDATE_USER
  );

  const [usePoint, { loading: mutationLoading, error: mutationError }] = useMutation(
    USER_QUERY.USE_POINT
  );

  useEffect(() => {
    if (!loading) {
      dispatch({ type: actionTypes.FETCH_DATA_SUCCESS, payload: data });
    }
  }, [data, loading]);

  const { userPointUseItem, isFetching } = state;

  return (
    <Styled.Container>
      <Styled.Header id="header">
        <PrevHeader />
      </Styled.Header>
      {isFetching ? (
        <SkeletonComponent />
      ) : (
        <>
          <Styled.ItemContent>
            <Styled.ItemImageWrap>
              <Styled.ItemImage itemImg={userPointUseItem.itemImg}>
                <div className="item-image" />
              </Styled.ItemImage>
            </Styled.ItemImageWrap>
            <Styled.ItemDescription>
              <div>
                <Styled.ItemTitle bold>
                  {userPointUseItem.itemBrandName}
                  <br />
                  {userPointUseItem.itemName}
                </Styled.ItemTitle>
                <Styled.ItemStampCost>{userPointUseItem.itemPoint}P</Styled.ItemStampCost>
              </div>
              <Styled.ItemNotice>
                <div className="due-date">
                  <div className="title">유효기간</div>
                  <div>지급일 기준 {userPointUseItem.itemLimitDay}일</div>
                </div>
                <div className="cautions">
                  <div>
                    <pre>{userPointUseItem.itemContent}</pre>
                  </div>
                </div>
              </Styled.ItemNotice>
            </Styled.ItemDescription>
          </Styled.ItemContent>
          <Styled.FixedBottom position-fixed>
            <Button
              bold
              disabled={userPointUseItem.itemState === 'terminated'}
              containerFluid
              onClick={async () => {
                if (await tryUsePoint(userPointUseItem)) {
                  await usePoint({
                    variables: {
                      itemId,
                    },
                  })
                    .then(async (res) => {
                      const { usePoint } = res.data;
                      if (usePoint) {
                        await successConfirm();
                      } else {
                        alert('구매 실패');
                      }
                    })
                    .catch(async (err) => {
                      console.log(err.message);
                      const text = ERROR_POPUP_TEXT[err.message](userPointUseItem);

                      if (await errorConfirm(text)) {
                        switch (err.message) {
                          case 'PHONENUM_NOT_PROVIDE':
                            router.push('/my/info');
                            break;
                          case 'REQUIRED_AGREE_MARKETING':
                            await updateUser({
                              variables: {
                                email: user.user.email,
                                agreeMarketingInform: true,
                              },
                            })
                              .then((res) => {
                                // const { updateUser } = res.data;
                                // if(updateUser) {
                                //   alert('내 정보를 수정하였습니다.');
                                //   window.location.reload();
                                // }
                              })
                              .catch((e) => {
                                console.log(e);
                              });
                            break;
                          case 'POINT_MISSION_INCOMPLETE':
                            window.open(
                              `https://bryan237381.typeform.com/to/Kae2lvpT#access_token=${getCookie(
                                { name: 'token' }
                              )}`,
                              '_blank'
                            );
                            // windowReference.location = `https://bryan237381.typeform.com/to/z5chjlYJ#access_token=${getCookie({name: 'token'})}`;
                            break;
                          case 'POINT_NOT_ENOUGH':
                            webShare({
                              referralCode: user.user.referralCode,
                            });
                            break;
                          default:
                            break;
                        }
                      }
                    });
                }
              }}
            >
              {userPointUseItem.itemState === 'terminated' ? '금일 수량 마감' : '교환하기'}
            </Button>
          </Styled.FixedBottom>
        </>
      )}
    </Styled.Container>
  );
};

export default Index;
