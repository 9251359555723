import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 -2rem;
  padding: 1.4rem 2rem;
  background-color: #fdff5d;
  font-size: 2.4rem;
`;

export const BoldText = styled.div`
  font-weight: bold;
`;

export const Highlight = styled.span`
  background-color: #fff;
  color: #ff5656;
  font-weight: bold;
  border-radius: 6px;
  padding: 0.2rem 1rem;
`;
