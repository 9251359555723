import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { Button } from 'components/atoms/Button';

export const RatioBox = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

export const PhotoWrapper = styled.div`
  margin-top: 1.6rem;
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(4, 1fr);
  .wrapper {
    border-radius: 2rem;
    overflow: hidden;
  }
`;

const disabledCSS = css`
  background-color: #ccc;
  color: #aaa;
`;
export const PhotoAddButton = styled.div`
  text-align: center;
  width: 100%;
  height: 5.8rem;
  line-height: 5.8rem;
  background-color: #666;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 2rem;
  ${(props) => props.disabled && disabledCSS}
`;
