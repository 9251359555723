import { useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';

import { getQuestion } from 'graphql/philing';

export default function useQuestion({ symptomId, order = 1, onComplete }) {
  const options = {
    // fetchPolicy: 'network-only',
    variables: {
      order,
      symptomId,
    },
    onCompleted: (res) => {
      onComplete(res.medicalQuestion);
    },
  };
  const { data, loading, refetch } = useQuery(getQuestion, options);

  let question = null;
  if (!loading && data.medicalQuestion) {
    question = Object.assign({}, data.medicalQuestion);
    const ops = [...question.options];
    question.options = orderBy(ops, ['order'], ['asc']);
  }

  return { question, loading, refetch };
}
