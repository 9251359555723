// userPointUseItem
// title: '타이틀이랍니다',
// text: `포인트 ${userPointUseItem.itemPoint/100}개를 기프티콘으로 교환하시겠어요?\n` +
//   '교환 후에는 환불이나 취소가 불가능합니다.',
// link: {
//   text: '링크랍니다',
//   url: 'https://odoctor.co.kr'
// }
// button: {
//   cancel: true,
//   ok: {
//     text: '교환하기',
//     callback: () => {
//       // alert('교환')
//     }
//   }
// },

export const PHONENUM_NOT_PROVIDE = () => ({
  text: '기프티콘을 받을 휴대폰 번호가 없습니다.\n연락처를 등록해주세요.',
  button: {
    cancel: true,
    ok: {
      text: '휴대폰 번호 등록하기',
      callback: () => {
        // alert('교환')
      },
    },
  },
});
export const POINT_MISSION_INCOMPLETE = () => ({
  text: '아주 간단한 미션을 완료하시면\n' + '바로 기프티콘을 교환하실 수 있습니다.',
  button: {
    ok: {
      text: '미션 끝내기',
      callback: () => {
        // alert('교환')
      },
    },
  },
});

export const POINT_NOT_ENOUGH = () => ({
  text: '보유한 포인트가 부족합니다\n' + '친구를 초대하고 포인트를 더 모아보세요.',
  button: {
    ok: {
      text: '친구 초대하기',
      callback: () => {
        // alert('교환')
      },
    },
  },
});

export const COUPON_SEND_FAILED = () => ({
  text:
    '오류가 발생하였습니다.\n' +
    '다시 교환을 요청해주세요.\n' +
    '반복적으로 오류가 발생했거나 포인트 손실이 발생한 경우 고객센터로 문의주시기 바랍니다.',
  button: {
    cancel: false,
    ok: {
      text: '확인',
      callback: () => {
        // alert('교환')
      },
    },
  },
});

export const REQUIRED_AGREE_MARKETING = () => ({
  text:
    '기프티콘을 교환하시려면 맞춤 혜택 및 광고성 정보 수신 동의가 필요해요.\n' + '동의하시겠어요?',
  button: {
    cancel: true,
    ok: {
      text: '동의하기',
      callback: () => {
        // alert('교환')
      },
    },
  },
});

export const INVALID_POINT_USE_ITEM = () => ({
  text: '유효한 상품이 아닙니다.',
  button: {
    cancel: false,
    ok: {
      text: '확인',
      callback: () => {
        // alert('교환')
      },
    },
  },
});
