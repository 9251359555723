import React, { useState, useEffect } from 'react';
import { numberPadding } from 'utils/number';

export const DayTimerComponent = ({ onTimeOut, timerReset, init = 3600 }) => {
  const [seconds, setSeconds] = useState(init - Math.floor(new Date() / 1000));
  const [isActive, setIsActive] = useState(true);

  function reset() {
    setSeconds(180);
    setIsActive(true);
  }

  useEffect(() => {
    timerReset && reset();
  }, [timerReset]);

  useEffect(() => {
    let interval = null;
    if (seconds === 0) {
      setIsActive(false);
      onTimeOut();
    }

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isActive, onTimeOut, seconds]);

  function format(time) {
    return `${time < 10 ? '0' : ''}${time}`;
  }

  return (
    <>
      {format(Math.floor(seconds / 3600))}:{format(Math.floor((seconds / 60) % 60))}:
      {seconds % 60 < 10 ? '0' : ''}
      {seconds - Math.floor(seconds / 60) * 60}
    </>
  );
};

export const timeToString = (time) => {
  const diffInHrs = time / 3600000;
  const hh = Math.floor(diffInHrs);

  const diffInMin = (diffInHrs - hh) * 60;
  const mm = Math.floor(diffInMin);

  const diffInSec = (diffInMin - mm) * 60;
  const ss = Math.floor(diffInSec);

  const diffInMs = (diffInSec - ss) * 100;
  const ms = Math.floor(diffInMs);

  return `${numberPadding(mm)}:${numberPadding(ss)}:${numberPadding(ms)}`;
};

export const ElapsedTimerComponent = ({ timeRef, onTimeOut, timerReset, initTime, startTime }) => {
  const [seconds, setSeconds] = useState(initTime || 0);
  const [isActive, setIsActive] = useState(true);
  const [sTime, setSTime] = useState(new Date());

  useEffect(() => {
    let interval = null;
    timeRef.current = seconds;
    if (seconds >= 60 * 60 * 1000) {
      setIsActive(false);
      onTimeOut();
    }

    if (isActive) {
      interval = setInterval(() => {
        setSeconds(Date.now() - sTime);
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, onTimeOut, sTime, seconds, timeRef]);

  return <>{timeToString(seconds)}</>;
};

const TimerComponent = ({ onTimeOut, timerReset, initTime = 180, width = 2 }) => {
  const [seconds, setSeconds] = useState(initTime);
  const [isHide, setHide] = useState(false);

  function reset() {
    setSeconds(initTime);
    setHide(false);
  }

  useEffect(() => {
    timerReset && reset();
  }, [timerReset]);

  useEffect(() => {
    let interval = null;
    if (seconds === 0) {
      setHide(true);
      onTimeOut();
    } else {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    !isHide && (
      <>
        {width >= 3 && `${numberPadding(Math.floor(seconds / 60 / 60))}:`}
        {width >= 2 && `${numberPadding(Math.floor((seconds / 60) % 60))}:`}
        {numberPadding(seconds - Math.floor(seconds / 60) * 60)}
      </>
    )
  );
};

export default TimerComponent;
