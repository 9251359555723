import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const ReviewWrapper = styled.div`
  padding: 2rem 2rem 0 2rem;
`;

export const Reviewer = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.4px;
`;

export const Body = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid #e1e3e2;
`;

export const Description = styled.div`
  white-space: pre-wrap;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.4px;
  padding-bottom: 2rem;
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin: 0 -0.5rem;
  margin-bottom: 2rem;
`;

export const Image = styled.div`
  margin: 0 0.5rem;
  width: calc(25% - 1rem);
  border-radius: 4px;
  overflow: hidden;

  img {
    object-fit: cover;
  }
`;

export const LoadMoreWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 10rem;
  padding: 0 2rem;
`;

export const ButtonLoadMore = styled.button`
  border: 1px solid #e1e3e2;
  width: 100%;
  background-color: #fff;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  letter-spacing: -0.65px;
  border-radius: 4px;
`;

export const FullSizeImageWrapper = styled.div`
  position: fixed;
  top: 0;
  background: rgba(10, 10, 10, 0.5);
  z-index: 999;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 420px;
  transform: translateX(-2rem);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;

export const ButtonClose = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  img {
    width: 100%;
  }
`;

export const FullSizeImageNav = styled.span`
  position: absolute;
  left: ${({ left }) => left && 0};
  right: ${({ right }) => right && 0};
  width: 5rem;
  height: 5rem;
  background: rgba(10, 10, 10, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 2.5rem;
  }
`;

export const EmptyDescription = styled.p`
  font-size: 1.6rem;
  color: #b1b3b2;
  font-weight: bold;
  text-align: center;
  padding-top: 5rem;
`;
