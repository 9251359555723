import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { getQuestion, SUBMIT_QUESTION_ANSWER } from 'graphql/philing';
// import { pixelEvent } from '@helper/pixel';

export default function useQuestionMutation({ symptomId, onComplete, onError }) {
  const option = {
    variables: {},
    onCompleted: (response) => {
      onComplete && onComplete(response.submitQuestionAnswer.nextQuestion);
    },
    onError: (err) => {
      onError && onError(err.message);
    },
  };

  const [mutate] = useMutation(SUBMIT_QUESTION_ANSWER, option);

  const submitQuestion = useCallback(
    (SubmitQuestionAnswerInput) => {
      return mutate({
        variables: {
          input: SubmitQuestionAnswerInput,
        },
        update: (cache, { data }) => {
          if (data.submitQuestionAnswer.nextQuestion) {
            cache.writeQuery({
              query: getQuestion,
              data: { medicalQuestion: data.submitQuestionAnswer.nextQuestion },
              variables: {
                symptomId: symptomId,
                order: data.submitQuestionAnswer.nextQuestion?.order,
              },
            });
          }
        },
      });
    },
    [mutate]
  );

  return [submitQuestion];
}
