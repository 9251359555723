import react, { useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';

import { ME } from 'graphql/user';

export const useUser = () => {
  const option = {
    onCompleted: (res) => {
      // dispatch(getUser.success(res.user));
    },
  };

  const { loading, error, data, refetch } = useQuery(ME, option);

  // useEffect(() => {
  //   // if no redirect needed, just return (example: already on /dashboard)
  //   // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
  //   if (!redirectTo || !user) return;

  //   if (
  //     // If redirectTo is set, redirect if the user was not found.
  //     (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
  //     // If redirectIfFound is also set, redirect if the user was found
  //     (redirectIfFound && user?.isLoggedIn)
  //   ) {
  //     Router.push(redirectTo);
  //   }
  // }, [user, redirectIfFound, redirectTo]);

  return { loading, user: data?.me, refetch };
};
