import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding-top: 9rem;
  font-size: 1.4rem;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: -0ch.55px;
  line-height: 1.33;
  margin-bottom: 2rem;
`;

export const Content = styled.div`
  line-height: 1.43;
  letter-spacing: -0.4px;
  margin-bottom: ${({ split }) => split && '1rem'};
`;

export const Button = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  margin: 0 -2rem;
  background-color: #000;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.4rem;
  transition: background-color 0.5s linear;
`;
