// import styled from 'styled-components';

// export const Container = styled.div`
//   border-top: 1px solid #d4d6d5;
//   width: 100%;
//   max-width: 420px;
//   margin: 0 -2rem;
//   position: fixed;
//   display: flex;
//   bottom: 0;
//   color: #b1b3b2;
//   font-size: 1rem;
//   font-weight: 600;
//   background-color: #fff;
// `;

// export const Name = styled.div`
//   font-weight: bold;
// `;

// export const Box = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   flex: 1;
//   padding: 0.5rem 0;
//   background-color: #fff;
//   cursor: pointer;

//   ${Name} {
//     color: ${({ isActive }) => (isActive ? '#000' : '#b1b3b2')};
//   }

//   svg g path {
//     stroke: ${({ isActive }) => isActive && '#000'};
//     /* stroke: ${({ isActive }) => (isActive ? '#000' : '#b1b3b2')}; */
//   }

//   &:active {
//     background-color: #f8f8f8;
//   }
// `;

// export const IconWrapper = styled.div``;
import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid #d4d6d5;
  width: 100%;
  max-width: 420px;
  margin: 0 -2rem;
  position: fixed;
  display: flex;
  bottom: 0;
  color: #b1b3b2;
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
`;

export const Name = styled.div`
  font-weight: bold;
`;

export const Box = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 0.5rem 0;
  background-color: #fff;
  cursor: pointer;

  ${Name} {
    color: ${({ isActive }) => (isActive ? '#000' : '#b1b3b2')};
  }

  svg g path {
    stroke: ${({ isActive }) => isActive && '#000'};
    /* stroke: ${({ isActive }) => (isActive ? '#000' : '#b1b3b2')}; */
  }

  /* &:active {
    background-color: #f8f8f8;
  } */
`;

export const IconWrapper = styled.div`
  width: 27px;
  height: 27px;
`;

export const Image = styled.img`
  display: ${({ active }) => (active ? 'block' : 'none')};
`;
