import React, { useState, useEffect } from 'react';
import { TextAreaRef } from 'components/atoms/Input';
import { InputRef } from 'components/atoms/Input';
import { PhotoUploader } from 'components/organisms/PhotoUploader';
import * as Styled from './index.styles';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import { RatioBox } from 'components/atoms/Image';
import TimerComponent from 'components/molecules/Timer';

export const QuestionType1 = ({ options, submit }) => {
  const [Choice, setChoice] = useState([]);
  let buttonRef = null;

  useEffect(() => {
    setChoice(
      options.map((answer) => {
        return {
          ...answer,
          checked: false,
        };
      })
    );
  }, [options]);

  const onClickAnswer = async (e) => {
    const choiceId = e.target.id;
    const updatedChoiceArray = Choice.map((answer) => ({
      ...answer,
      checked: false,
      ...(answer.id === choiceId && { checked: !answer.checked }),
    }));

    setChoice(updatedChoiceArray);

    const _bf = buttonRef;

    setTimeout(() => {
      _bf.click();
    }, 100);
  };

  const onClickSubmit = async () => {
    const userAnswer = Choice.filter((answer) => answer.checked).reduce(
      (lastAnswer, answer) => {
        lastAnswer.optionIds.push(answer.id);
        lastAnswer.value.push(answer.title);

        return lastAnswer;
      },
      {
        optionIds: [],
        value: [],
      }
    );

    submit(userAnswer);
  };

  const disabledNextButton = !find(Choice, { checked: true });

  return (
    <div>
      {orderBy(Choice, ['order']).map((answer, idx) => {
        const { id, title, checked, imageUrl } = answer;
        const className = disabledNextButton ? '' : checked ? 'active' : 'in-active';

        return (
          <div key={idx} style={{ margin: '1rem 0' }}>
            <Styled.AnswerButton
              image={imageUrl}
              id={id}
              onClick={onClickAnswer}
              className={`${className}`}
            >
              {title}
            </Styled.AnswerButton>
          </div>
        );
      })}

      {/* 단답형 버튼 숨김 */}
      <Styled.NextButton
        style={{ display: 'none' }}
        ref={(ele) => (buttonRef = ele)}
        className={`${disabledNextButton ? '' : 'active'}`}
        onClick={onClickSubmit}
        disabled={disabledNextButton}
      >
        다음
      </Styled.NextButton>
    </div>
  );
};

export const QuestionType2 = ({ children, options, submit }) => {
  const [Choice, setChoice] = useState([]);

  useEffect(() => {
    setChoice(
      options.map((answer) => {
        return {
          ...answer,
          checked: false,
        };
      })
    );
  }, [options]);

  const onClickSubmit = async () => {
    // const userAnswer = Choice.reduce((lastAnswer, c, index) => {
    //   return [...lastAnswer, ...[c.checked && c.id].filter(Boolean)];
    // }, []);

    const userAnswer = Choice.filter((answer) => answer.checked).reduce(
      (lastAnswer, answer) => {
        lastAnswer.optionIds.push(answer.id);
        lastAnswer.value.push(answer.title);

        return lastAnswer;
      },
      {
        optionIds: [],
        value: [],
      }
    );
    submit(userAnswer);
  };

  const onClickAnswer = (e) => {
    const choiceId = e.target.id;
    const updatedChoiceArray = Choice.map((answer) => ({
      ...answer,
      ...(answer.id === choiceId && { checked: !answer.checked }),
    }));
    setChoice(updatedChoiceArray);
  };

  const disabledNextButton = !find(Choice, { checked: true });

  return (
    <>
      <div style={{ paddingBottom: '14rem' }}>
        {orderBy(Choice, ['order']).map((answer, idx) => {
          const { id, title, checked, imageUrl } = answer;
          const className = disabledNextButton ? '' : checked ? 'active' : ''; // in-active
          return (
            <div key={idx} style={{ margin: '1rem 0' }}>
              <Styled.AnswerButton
                id={id}
                image={imageUrl}
                onClick={onClickAnswer}
                className={`${className}`}
              >
                {title}
              </Styled.AnswerButton>
            </div>
          );
        })}
      </div>
      <Styled.FixedBottom>
        <Styled.NextButton
          className={`${disabledNextButton ? '' : 'active'}`}
          onClick={onClickSubmit}
          disabled={disabledNextButton}
        >
          다음
        </Styled.NextButton>
      </Styled.FixedBottom>

      {/* {React.cloneElement(
        <Styled.FixedBottom>
          <Styled.NextButton
            className={`${disabledNextButton ? '' : 'active'}`}
            onClick={onClickSubmit}
            disabled={disabledNextButton}
          >
            다음 {Choice.length}
          </Styled.NextButton>
        </Styled.FixedBottom>,
        {},
        children
      )} */}

      {/* <div onClick={onClickSubmit}>정답 제출하기</div> */}
    </>
  );
};

export const QuestionType3 = ({ placeHolderText = '', options, submit }) => {
  let taComment = null;
  const [active, setActive] = useState(false);

  const onClickSubmit = () => {
    submit({
      optionIds: null,
      value: [taComment.value],
    });
    taComment.value = null;
  };

  useEffect(() => {
    setActive(false);
  }, [options]);

  return (
    <div>
      <div>
        <Styled.TextareaWrap className={`${active ? 'active' : ''}`}>
          <TextAreaRef
            inputRef={(el) => (taComment = el)}
            placeholder={placeHolderText}
            onChange={(e) => {
              // e.target.style.height = '1px';
              // e.target.style.height = e.target.scrollHeight + 'px';
              e.target.style.height = '100%';
              if (e.target.value !== '') setActive(true);
              if (e.target.value === '') setActive(false);
            }}
          />
        </Styled.TextareaWrap>
      </div>

      <Styled.FixedBottom>
        {!active && (
          <div>
            <Styled.SkipButton onClick={onClickSubmit}>건너뛰기</Styled.SkipButton>
          </div>
        )}
        <Styled.NextButton
          className={`${!active ? '' : 'active'}`}
          onClick={onClickSubmit}
          disabled={!active}
        >
          다음
        </Styled.NextButton>
      </Styled.FixedBottom>
    </div>
  );
};

export const QuestionType4 = ({ submit }) => {
  const [photo, setPhoto] = useState([]);
  const [active, setActive] = useState(false);
  // 기본값 testFolder
  const reviewFolder = false; //`pick/review/${eventId}`;

  const onClickSubmit = () => {
    submit({
      optionIds: null,
      value: photo.map((p) => p.file),
    });
  };

  return (
    <div>
      <div style={{ paddingBottom: '14.rem' }}>
        {/*
         사진 추가 중인 상태 photo -> false
         업로드가 완료면 photo -> [...]
         사진을 지울때도 onFinal리 호출되면서 []를 반환함
        */}
        <PhotoUploader
          maxLength={8}
          path={reviewFolder}
          onInit={() => {
            setPhoto(false);
          }}
          onFinal={(uploadFileList) => {
            // [0]: {file: "033.kn8ark41.jpg", s: true}
            // [1]: {file: "034.kn8ark43.jpg", s: true}
            setPhoto(uploadFileList);
            setActive(uploadFileList.length > 0);
          }}
        />
      </div>

      <Styled.FixedBottom>
        {!active && (
          <div>
            <Styled.SkipButton onClick={onClickSubmit}>건너뛰기</Styled.SkipButton>
          </div>
        )}
        <Styled.NextButton
          className={`${!active ? '' : 'active'}`}
          onClick={onClickSubmit}
          disabled={!active}
        >
          다음
        </Styled.NextButton>
      </Styled.FixedBottom>
    </div>
  );
};

export const CardListButton = ({ options, onClick, addCard }) => {
  const [Choice, setChoice] = useState([]);

  useEffect(() => {
    setChoice(
      options.map((option) => {
        return {
          ...option,
          checked: false,
        };
      })
    );
  }, [options]);

  const onClickAnswer = (e) => {
    const choiceId = e.target.id;
    const updatedChoiceArray = Choice.map((answer) => ({
      ...answer,
      checked: false,
      ...(answer.id === choiceId && { checked: !answer.checked }),
    }));
    setChoice(updatedChoiceArray);
  };

  const disabledNextButton = !find(Choice, { checked: true });

  return (
    <>
      <div style={{ paddingBottom: '14rem' }}>
        {Choice.map((answer, idx) => {
          const { id, cardNum, cardName, checked } = answer;
          const className = disabledNextButton ? '' : checked ? 'active' : ''; // in-active
          return (
            <div key={idx} style={{ margin: '1rem 0' }}>
              <Styled.AnswerButton id={id} onClick={onClickAnswer} className={`${className}`} card>
                {[cardName, cardNum].map((n, i) => {
                  return (
                    <div key={i} className={`${n}`} id={id}>
                      {n}
                    </div>
                  );
                })}
              </Styled.AnswerButton>
            </div>
          );
        })}
        <div style={{ marginTop: '1.6rem' }}>
          <Styled.AddCardButton onClick={addCard}>새로운 카드 등록하기 +</Styled.AddCardButton>
        </div>
      </div>

      <Styled.FixedBottom>
        <Styled.NextButton
          className={`${disabledNextButton ? '' : 'active'}`}
          onClick={() => {
            onClick(find(Choice, { checked: true }));
          }}
          disabled={disabledNextButton}
        >
          다음
        </Styled.NextButton>
      </Styled.FixedBottom>

      {/* <div onClick={onClickSubmit}>정답 제출하기</div> */}
    </>
  );
};

export const PaperInput = ({
  label,
  initValue,
  errorMsg,
  onChange,
  onBlur,
  validate,
  ...others
}) => {
  const [displayError, setDisplayError] = useState(false);
  const [isPass, setIsPass] = useState(false);

  let inputRef = null;

  useEffect(() => {
    inputRef.value = initValue;
    if (validate) {
      setIsPass(validate(initValue));
      if (initValue && initValue.length > 0) {
        setDisplayError(!validate(initValue));
      }
    }
  }, [initValue]);

  return (
    <div>
      {label && <Styled.Label>{label}</Styled.Label>}
      <Styled.InputWrap className={`${isPass ? 'active' : displayError ? 'error' : ''}`}>
        <span className="msg-error">{errorMsg}</span>
        <InputRef
          {...others}
          inputRef={(el) => (inputRef = el)}
          onChange={() => {
            if (validate) {
              setIsPass(validate(inputRef.value));
            }
            onChange(inputRef.value);
          }}
          onBlur={() => {
            onBlur && onBlur();
            setDisplayError(true);
          }}
        />
      </Styled.InputWrap>
    </div>
  );
};

export const PaperRRNInput = ({
  label,
  initValue,
  errorMsg,
  onChange,
  onBlur,
  validate,
  disabled,
  placeholder,
  maxLength,
  ...others
}) => {
  const [displayError, setDisplayError] = useState(false);
  const [isPass, setIsPass] = useState(false);

  let inputRef = null;
  let inputRef2 = null;

  useEffect(() => {
    inputRef.value = initValue[0];
    inputRef2.value = initValue[1];
  }, [initValue]);

  return (
    <div>
      {label && <Styled.Label>{label}</Styled.Label>}

      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 -.6rem' }}>
        <Styled.InputWrap
          style={{ margin: 0, width: '49%' }}
          className={`text-center ${isPass ? 'active' : displayError ? 'error' : ''} ${
            disabled[0] ? 'disabled' : ''
          }`}
        >
          <InputRef
            disabled={disabled[0]}
            placeholder={placeholder[0]}
            maxLength={maxLength[0]}
            {...others}
            inputRef={(el) => (inputRef = el)}
            onChange={() => {
              const rrn = `${inputRef.value}-${inputRef2.value}`;
              if (validate) {
                setIsPass(validate(rrn));
              }
              onChange(rrn);
            }}
            onBlur={() => {
              onBlur && onBlur();
              setDisplayError(true);
            }}
          />
        </Styled.InputWrap>
        <Styled.InputWrap
          style={{ margin: 0, width: '49%' }}
          className={`text-center ${isPass ? 'active' : displayError ? 'error' : ''} ${
            disabled[1] ? 'disabled' : ''
          }`}
        >
          <span className="msg-error">{errorMsg}</span>
          <InputRef
            disabled={disabled[1]}
            placeholder={placeholder[1]}
            maxLength={maxLength[1]}
            {...others}
            inputRef={(el) => (inputRef2 = el)}
            onChange={() => {
              const rrn = `${inputRef.value}-${inputRef2.value}`;
              if (validate) {
                setIsPass(validate(rrn));
              }
              onChange(rrn);
            }}
            onBlur={() => {
              onBlur && onBlur();
              setDisplayError(true);
            }}
          />
        </Styled.InputWrap>
      </div>
    </div>
  );
};

export const PaperPinInput = ({ label, onChange, onTimeOut, onBlur, validate, cnt, ...others }) => {
  const initState = [null, null, null, null];
  const [vp, setVp] = useState([...initState]);

  useEffect(() => {
    onChange(vp.join(''));
  }, [vp]);

  useEffect(() => {
    setVp([...initState]);
  }, [cnt]);

  const focusNextInput = ({ name, value, maxLength }) => {
    const [fieldName, fieldIndex] = name.split('-');

    // 탭이동
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 3) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=pinInput-${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  return (
    <div>
      {label && (
        <Styled.Label>
          {label}
          <Styled.TimerWrap>
            <TimerComponent
              initTime={180}
              timerReset={cnt} // reset cnt를 변경해서..
              onTimeOut={() => {
                onTimeOut();
              }}
            />
          </Styled.TimerWrap>
        </Styled.Label>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 -1rem' }}>
        {vp.map((v, i) => {
          return (
            <Styled.InputWrap
              key={i}
              pin
              className={`text-center ${validate(v) ? 'active' : v !== null ? 'error' : ''}`}
            >
              <RatioBox>
                <InputRef
                  {...others}
                  max={9}
                  value={v || ''}
                  name={`pinInput-${i}`}
                  onChange={(e) => {
                    const tmp = vp;
                    tmp[i] = validate(e.target.value) ? e.target.value : false;
                    setVp([...tmp]);
                    const { name, maxLength, value } = e.target;
                    focusNextInput({ name, maxLength, value });
                  }}
                  onBlur={() => {
                    onBlur && onBlur();
                    // setDisplayError(true);
                  }}
                />
              </RatioBox>
            </Styled.InputWrap>
          );
        })}
      </div>
    </div>
  );
};

export const PaperCheckbox = ({ label, initValue, onChange }) => {
  // const [displayError, setDisplayError] = useState(false);
  // const [isPass, setIsPass] = useState(false);

  useEffect(() => {}, [initValue]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Styled.CheckboxWrap
        // className={`text-center ${isPass ? 'active' : displayError ? 'error' : ''}`}
        >
          <input
            className="agree-checkbox"
            type="checkbox"
            name="agree"
            id="agree"
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          />
          <label htmlFor="agree" className="agree-checkbox-label">
            {label}
          </label>
        </Styled.CheckboxWrap>
      </div>
    </div>
  );
};
