import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import find from 'lodash/find';
import * as Styled from './index.style';
import { useUser } from 'hooks/useUser';

import {
  submitQuestionAnswer,
  submitQuestionPaper,
  setPopup,
  editQuestionPaper,
} from 'modules/form/form.actions';

import {
  QuestionType1,
  QuestionType2,
  QuestionType3,
  QuestionType4,
} from 'components/molecules/Question';
import useQuestion from 'hooks/philing/useQuestion';
import useQuestionMutation from 'hooks/philing/useQuestionMutation';
import useQuestionPaperMutation from 'hooks/philing/useQuestionPaperMutation';
import { useSelector, useDispatch } from 'react-redux';
import { getCookie } from 'utils/cookie';
import { confirm } from 'components/molecules/Modal';
import ERROR_MSG from 'config/errorMessage';

const questionTypes = [
  {
    id: '1',
    inputKey: 'optionIds',
    paperKey: 'optionValues',
    component: QuestionType2,
  },
  {
    id: '2',
    inputKey: 'optionIds',
    paperKey: 'optionValues',
    component: QuestionType1,
  },
  {
    id: '3',
    inputKey: 'textAnswer',
    paperKey: 'textAnswer',
    component: QuestionType3,
  },
  {
    id: '4',
    inputKey: 'imageAnswers',
    paperKey: 'imageAnswers',
    component: QuestionType4,
  },
];

// 로그인 팝업을 띄워서, 페이지를 벗어나지 않고 로그인 할 수 있도록..
// 같은 도메인(odoctor.co.kr)에서 iframe을 띄우고 그 안에서 카카오 로그인창을 띄워서,
// 카카오 x-origin 회피
const PopupLoginComponent = ({ callBack }) => {
  // isPopUpLogin = interval 콜백 내부에서 token을 감지하고
  // fast refresh 하기위해서 씀
  const [isPopUpLogin, setPopupLogin] = useState(false);
  const { user, refetch } = useUser();
  const [activeLoginPopup, setActiveLoginPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) {
      // token already exists
      callBack();
      return;
    }

    // token dose not exists, waiting for token
    const waitToken = setInterval(() => {
      const t = getCookie({ name: 'token' });
      if (t) {
        refetch(); // user query update
        clearInterval(waitToken);
        setPopupLogin(true);
      }
    }, 1000);

    setActiveLoginPopup(true);
  }, []);

  useEffect(() => {
    if (isPopUpLogin) {
      setActiveLoginPopup(false);
      callBack();
    }
  }, [isPopUpLogin]);

  return activeLoginPopup && <iframe src={`/login`} width="100%" height="100%" />;
};

const Question = ({ symptomId, treatmentId }) => {
  const { PAPER_FORM, POPUP_PAGE } = useSelector((state) => state.form);
  const router = useRouter();
  const dispatch = useDispatch();
  const [componentName, currentStep] = router.query.page.split('-');

  useQuestion({
    symptomId,
    order: +currentStep,
    onComplete: (question) => {
      dispatch(
        editQuestionPaper({
          key: 'PAPER_FORM',
          question,
        })
      );
    },
  });

  // 문진 정답 제출
  // 성공 medicalQuestion: {}
  // 마지막 문진 medicalQuestion: null
  const [submitQuestion] = useQuestionMutation({
    symptomId,
    onError: async (error) => {
      let movePage = `/my/medi/${treatmentId}`;
      switch (error) {
        case 'INVALID_TREATMENT':
        case 'TREATMENT_QUESTION_PAPER_ALREADY_EXIST':
        case 'INVALID_DEPARTMENT':
        case 'INVALID_TREATMENT_STATUS':
        case 'INVALID_MEDICAL_PAPER_ANSWER':
          movePage = `/my/medi/${treatmentId}`;
          break;
      }

      await confirm({
        text: ERROR_MSG.SUBMIT_QUESTION_ANSWER[error] || ERROR_MSG.UNKNOWN,
        button: {
          cancel: false,
          ok: {
            text: '확인',
          },
        },
      });

      router.push(movePage);
    },
  });

  const [submitPaper] = useQuestionPaperMutation({
    CreateQuestionPaperInput: {
      treatmentId,
      medicalDepartmentId: symptomId,
      answers: map(
        pickBy(PAPER_FORM.paperResponse, (v, k) => {
          return /question-/gi.test(k);
        }),
        (o) => omit(o, ['optionIds', 'treatmentId'])
      ), // [{questionId, value: []}]
    },
    onError: async (error) => {
      let movePage = `/my/medi/${treatmentId}`;
      switch (error) {
        case 'INVALID_TREATMENT_STATUS':
          movePage = `/my/medi/${treatmentId}`;
          break;
      }

      await confirm({
        text: ERROR_MSG.SUBMIT_QUESTION_ANSWER[error] || ERROR_MSG.UNKNOWN,
        button: {
          cancel: false,
          ok: {
            text: '확인',
          },
        },
      });

      router.push(movePage);
    },
  });

  if (!PAPER_FORM.paper.options) {
    return <div></div>;
  }

  if (!PAPER_FORM.paper) {
    return <div>답변 처리중</div>;
  }
  const { id, type, options } = PAPER_FORM.paper;

  const Type = find(questionTypes, { id: type.id });
  const Component = Type.component;

  return (
    <Styled.Section>
      {POPUP_PAGE.login && (
        <div
          style={{
            top: 0,
            left: 0,
            backgroundColor: '#fff',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 9999,
          }}
        >
          <PopupLoginComponent
            callBack={() => {
              // const pickedQuestionAnswer = pickBy(PAPER_FORM.paperResponse, (v, k) => {
              //   return /question-/gi.test(k);
              // });
              // const a = map(pickedQuestionAnswer, (o) => omit(o, ['optionIds']));
              dispatch(
                submitQuestionPaper({
                  callback: submitPaper,
                  key: 'PAPER_FORM',
                  page: PAPER_FORM.page,
                  treatmentId,
                })
              );
              dispatch(
                setPopup({
                  page: 'login',
                  value: false,
                })
              );
            }}
          />
        </div>
      )}
      <Component
        options={options}
        submit={async (userInput) => {
          window.scrollTo(0, 0);
          dispatch(
            submitQuestionAnswer({
              callback: submitQuestion,
              key: 'PAPER_FORM',
              nullNextPage: 'personal-information', // nextQuestion이 없을때 쓰는 페이지
              page: PAPER_FORM.page,
              answer: {
                treatmentId,
                questionId: id,
                ...userInput,
              },
            })
          );
        }}
      />
    </Styled.Section>
  );
};

export default Question;
