import React, { useEffect } from 'react';
import * as Styled from './index.style';
import usePayments from 'hooks/philing/usePayments';

import { CardListButton } from 'components';

const PhilingPayments = ({ saveAnswer }) => {
  const [fetchData, isFetching, data, refetch] = usePayments();

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (card) => {
    if (card) {
      const { id, cardName } = card;
      saveAnswer({
        response: `${name}`,
        info: {
          payment: {
            id,
            cardName,
          },
        },
      });
    }
  };

  const handleAddCard = () => {
    const winObj = window.open(`/pay/card?popup=1`);
    var loop = setInterval(function () {
      if (winObj.closed) {
        clearInterval(loop);
        refetch();
      }
    }, 500);
  };

  return (
    <div>
      <div>
        <Styled.Section>
          {!isFetching && (
            <CardListButton
              options={data.userPaymentMethods}
              onClick={handleSubmit}
              addCard={handleAddCard}
            />
          )}
        </Styled.Section>
      </div>
    </div>
  );
};

export default PhilingPayments;
