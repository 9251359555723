import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation ($phonenum: String!, $password: String!) {
    loginUserByPhone(phonenum: $phonenum, password: $password) {
      ok
      isPartner
      token
      refreshToken
      errors {
        path
        message
        code
      }
    }
  }
`;

/* 미사용: kakao 로그인 사용
  const SIGNUP = gql`
  mutation($phonenum: String!, $username: String!, $password: String!, $agreemarket: Boolean) {
    registerUserByPhone(phonenum: $phonenum, username: $username, password: $password, agreemarket: $agreemarket) {
      ok
      user {
        id
        username
      }
      errors {
        path
        message
        code
      }
    }
  }
`; */

const USE_POINT = gql`
  mutation ($itemId: ID!) {
    usePoint(itemId: $itemId)
  }
`;

export const IS_USER_PHONE = gql`
  mutation ($phonenum: String!) {
    checkPhonenum(phonenum: $phonenum) {
      ok
    }
  }
`;

export const GET_CERTIFY_PIN = gql`
  mutation ($phonenum: String!) {
    sendPhonePin(phonenum: $phonenum) {
      ok
      pinId
    }
  }
`;

export const IS_CERTIFY_PHONE = gql`
  mutation ($pinId: ID!, $pin: String!) {
    confirmPhonePin(pinId: $pinId, pin: $pin) {
      ok
    }
  }
`;

export const GET_REWARD_ITEM = gql`
  query {
    userPointRewardItems {
      id
      itemName
      itemCode
      itemDescription
      itemPoint
      metadata
    }
    userPointUseItems {
      id
      itemName
      itemPoint
      itemBrandName
      itemBrandImg
      itemCode
      itemState
      metadata
    }
    myPoint {
      pointAmount
      referralCode
    }
  }
`;

export const GET_REWARD_ITEM_DETAIL = gql`
  query ($itemId: ID!) {
    userPointUseItem(id: $itemId) {
      itemName
      itemImg
      itemBrandName
      itemLimitDay
      itemContent
      itemPoint
      itemState
    }
    myPoint {
      pointAmount
      referralCode
    }
  }
`;

export const GET_MY_POTIN = gql`
  {
    UserPointResponse {
      pointAmount
      referralCode
      ok
    }
  }
`;

export const idk = {};

// v2
export const ME = gql`
  {
    me {
      id
      email
      provider
      phonenum
      name
      gender
      ageGroup
      agreeMarketingInform
      referralCode
      birthDate
      nickname
      avatar
    }
  }
`;

export const UPDATE_USER = gql`
  mutation (
    $email: String
    $phonenum: String
    $name: String
    $agreeMarketingInform: Boolean
    $avatar: String
    $nickname: String
  ) {
    updateUser(
      user: {
        email: $email
        phonenum: $phonenum
        name: $name
        agreeMarketingInform: $agreeMarketingInform
        avatar: $avatar
        nickname: $nickname
      }
    ) {
      email
      phonenum
      name
      agreeMarketingInform
    }
  }
`;

export const CREATE_USER = gql`
  mutation (
    $provider: Provider!
    $email: String!
    $gender: Gender
    $name: String
    $ageGroup: AgeGroup
    $agreeMarketingInform: Boolean
    $referralCode: String
  ) {
    createUser(
      user: {
        provider: $provider
        email: $email
        gender: $gender
        name: $name
        ageGroup: $ageGroup
        agreeMarketingInform: $agreeMarketingInform
        referralCode: $referralCode
      }
    ) {
      id
      provider
      email
      phonenum
      name
      gender
      ageGroup
      agreeMarketingInform
    }
  }
`;

export const GET_POINT_HISTORY = gql`
  query {
    userPointHistory(pagination: { perPage: 10, currentPage: 1 }) {
      data {
        id
        pointAmount
        eventType
        createdAt
        rewardItem {
          id
          itemName
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GET_INVITATION_RANK = gql`
  query ($limit: Int) {
    inviteEventRanks(limit: $limit) {
      # 로그인 한 유저의 순위
      myRank {
        id
        rank
        username
        userId
        inviteCount
      }
      # 친구 초대 이벤트 순위
      rankList {
        id
        username
        rank
        inviteCount
        createdAt
      }
      event {
        id
        startDate
        endDate
      }
    }
  }
`;

export default {
  LOGIN,
  ME,
  CREATE_USER,
  UPDATE_USER,
  IS_USER_PHONE,
  GET_CERTIFY_PIN,
  IS_CERTIFY_PHONE,
  GET_REWARD_ITEM,
  GET_REWARD_ITEM_DETAIL,
  USE_POINT,
  GET_POINT_HISTORY,
  GET_INVITATION_RANK,
};
