import React, { useEffect } from 'react';
import * as Styled from './index.style';

const INTRO_MESSAGE = '거의 끝났습니다!\n이제 접수 정보만 입력하면 끝이에요!';
const INTRO_SUBSCRIPTION = '입력하신 접수 정보는\n모두 안전하게 암호화되니까 걱정마세요';

const IntroQuestion = ({ autoSkip }) => {
  useEffect(() => {
    autoSkip(3000);
  }, []);

  return (
    <Styled.Section d-flex flex-column align-items-center>
      <Styled.Title style={{ textAlign: 'center' }}>{INTRO_MESSAGE}</Styled.Title>
      <Styled.BgImageContainer />
      <Styled.Describe style={{ textAlign: 'center' }}>{INTRO_SUBSCRIPTION}</Styled.Describe>
    </Styled.Section>
  );
};

export default IntroQuestion;
