import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';

import * as Styled from './index.styles';
import { RatioBox } from 'components/atoms/Image';
import { GetEventReviews } from 'graphql/review';
import { LazyImage } from 'components';

const ArrowRight = '/assets/images/icons/arrow-down-grey.svg';
const IconClose = '/assets/images/icons/btn-close-fat.svg';
const REVEIW_PHOTO_URL = 'https://odoctor-pics.s3.ap-northeast-2.amazonaws.com';

export default function ReviewBoard({ eventId }) {
  const [fullSizeImage, setFullSizeImage] = useState([]);
  const [visibleFullSizeImage, setvisibleFullSizeImage] = useState(false);

  const { data, fetchMore } = useQuery(GetEventReviews, {
    variables: { eventId },
  });

  useEffect(() => {
    if (document) {
      const [body] = document.getElementsByTagName('body');
      if (visibleFullSizeImage) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    }
  }, [visibleFullSizeImage]);

  const handleFullSizeImage = (images, idx) => () => {
    setFullSizeImage(
      images.map((image, index) => ({
        ...image,
        active: index === idx,
      }))
    );
    setvisibleFullSizeImage(true);
  };

  const handleResetFullSizeImage = () => {
    setvisibleFullSizeImage(false);
    setFullSizeImage([]);
  };

  const handleNav = (direction) => (e) => {
    e.stopPropagation();

    let currentActiveIndex = 0;
    let nextActiveIndex = 0;
    fullSizeImage.forEach((image, idx) => {
      if (image.active) {
        currentActiveIndex = idx;
      }
    });

    switch (direction) {
      case 'left':
        nextActiveIndex =
          currentActiveIndex === 0 ? fullSizeImage.length - 1 : currentActiveIndex - 1;
        break;
      case 'right':
        nextActiveIndex =
          currentActiveIndex + 1 < fullSizeImage.length ? currentActiveIndex + 1 : 0;
        break;
    }

    const newImages = fullSizeImage.map((image, idx) => ({
      ...image,
      active: nextActiveIndex === idx,
    }));

    setFullSizeImage(newImages);
  };

  const [activeImage] = fullSizeImage.filter(({ active }) => active);

  if (!data) {
    return <Loading />;
  }

  const isShowButtonMore =
    data.eventReviews.pagination.total !== 0 &&
    data.eventReviews.pagination.currentPage !== data.eventReviews.pagination.lastPage;

  const isDrawArrow = fullSizeImage.length > 1;

  return (
    <div>
      <Styled.Container>
        {data.eventReviews.data.length === 0 && (
          <Styled.EmptyDescription>등록된 리뷰가 없습니다.</Styled.EmptyDescription>
        )}
        {data.eventReviews.data.map(({ id, nickname, detail, images }) => (
          <Styled.ReviewWrapper key={id}>
            <Styled.Reviewer>{nickname}</Styled.Reviewer>
            <Styled.Body>
              <Styled.Description>{detail}</Styled.Description>
              {images.length !== 0 && (
                <Styled.ImageWrapper>
                  {images.map(({ id: imageId, url }, idx) => (
                    <Styled.Image key={imageId} onClick={handleFullSizeImage(images, idx)}>
                      <RatioBox>
                        <LazyImage src={`${REVEIW_PHOTO_URL}/${url}`} alt="리뷰이미지" />
                      </RatioBox>
                    </Styled.Image>
                  ))}
                </Styled.ImageWrapper>
              )}
            </Styled.Body>
            {visibleFullSizeImage && (
              <Styled.FullSizeImageWrapper>
                <Styled.ButtonClose onClick={handleResetFullSizeImage}>
                  <img src={IconClose} alt="닫기" />
                </Styled.ButtonClose>
                {isDrawArrow && (
                  <Styled.FullSizeImageNav left onClick={handleNav('left')}>
                    <img src={ArrowRight} alt="이동" style={{ transform: 'rotate(90deg)' }} />
                  </Styled.FullSizeImageNav>
                )}
                <img src={`${REVEIW_PHOTO_URL}/${activeImage.url}`} />
                {isDrawArrow && (
                  <Styled.FullSizeImageNav right onClick={handleNav('right')}>
                    <img src={ArrowRight} alt="이동" style={{ transform: 'rotate(-90deg)' }} />
                  </Styled.FullSizeImageNav>
                )}
              </Styled.FullSizeImageWrapper>
            )}
          </Styled.ReviewWrapper>
        ))}
        <Styled.LoadMoreWrapper>
          {isShowButtonMore && (
            <Styled.ButtonLoadMore
              onClick={() => {
                fetchMore({
                  variables: {
                    eventId,
                    pagination: {
                      perPage: 10,
                      currentPage: data.eventReviews.pagination.currentPage + 1,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      eventReviews: {
                        data: [...prev.eventReviews.data, ...fetchMoreResult.eventReviews.data],
                        pagination: fetchMoreResult.eventReviews.pagination,
                      },
                    });
                  },
                });
              }}
            >
              리뷰 더보기
            </Styled.ButtonLoadMore>
          )}
        </Styled.LoadMoreWrapper>
      </Styled.Container>
    </div>
  );
}

const Loading = () => (
  <div>
    <Styled.Container style={{ padding: '2rem' }}>
      <Skeleton count={1} height="2rem" />
      <div>
        <Skeleton style={{ marginTop: '1rem' }} height="8rem" />
        <Skeleton style={{ marginTop: '1rem' }} height="4rem" />
      </div>
      <Skeleton count={1} height="2rem" style={{ marginTop: '2rem' }} />
      <div>
        <Skeleton style={{ marginTop: '1rem' }} height="8rem" />
        <Skeleton style={{ marginTop: '1rem' }} height="4rem" />
      </div>
    </Styled.Container>
  </div>
);
