import { gql } from '@apollo/client';

export const GetPickMain = gql`
  query {
    bestEvents {
      id
      hospital {
        id
        name
      }
      name
      originalPrice
      discountedPrice
      discountRate
      applyCount
      reservationCount
      thumbnailUrl
      reviewCnt
      totalRatingCount
      topRatingCount
    }
    newEvents {
      id
      hospital {
        id
        name
      }
      name
      originalPrice
      discountedPrice
      discountRate
      applyCount
      reservationCount
      thumbnailUrl
      reviewCnt
      totalRatingCount
      topRatingCount
    }
  }
`;

export const NewEvents = gql`
  query ($categoryId: ID) {
    newEvents(categoryId: $categoryId) {
      id
      hospital {
        id
        name
      }
      name
      originalPrice
      discountedPrice
      discountRate
      applyCount
      reservationCount
      thumbnailUrl
      reviewCnt
      totalRatingCount
      topRatingCount
    }
  }
`;

export const BestEvents = gql`
  query ($categoryId: ID) {
    bestEvents(categoryId: $categoryId) {
      id
      hospital {
        id
        name
      }
      name
      originalPrice
      discountedPrice
      discountRate
      applyCount
      reservationCount
      thumbnailUrl
      reviewCnt
      totalRatingCount
      topRatingCount
    }
  }
`;

export const GetEventCategories = gql`
  query ($parentCategoryId: ID) {
    eventCategories(parentCategoryId: $parentCategoryId) {
      id
      name
    }
  }
`;

export const GetEventsByCategoryId = gql`
  query ($categoryId: ID) {
    eventCategories {
      id
      name
    }
    bestEvents(categoryId: $categoryId) {
      id
      hospital {
        id
        name
      }
      name
      originalPrice
      discountedPrice
      discountRate
      applyCount
      reservationCount
      thumbnailUrl
      reviewCnt
      totalRatingCount
      topRatingCount
    }
  }
`;

export const GetEvents = gql`
  query ($filter: EventListFilter, $pagination: PaginationInput) {
    events(filter: $filter, pagination: $pagination) {
      data {
        id
        name
        hospital {
          id
          name
        }
        reservationCount
        description
        therapyName
        contentPageUrl
        thumbnailUrl
        wideThumbnailUrl
        metaTag {
          id
          title
          description
          keywords
          ogUrl
          ogImage
        }
        startDate
        endDate
        discountRate
        discountedPrice
        originalPrice
        applyCount
        categories {
          id
          name
        }
        reviewCnt
        totalRatingCount
        topRatingCount
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetEventUsers = gql`
  query GetEventUsers($id: ID!) {
    eventUsers(id: $id) {
      username
      userPhonenum
      createdAt
    }
  }
`;

export const GetEventPackages = gql`
  query ($pagination: PaginationInput) {
    eventPackages(pagination: $pagination) {
      data {
        id
        startDate
        endDate
        name
        description
        contentsImageUrl
        discountRate
        applyCount
        thumbnailUrl
        events {
          id
          name
          hospital {
            id
            name
            location {
              id
              siName
              gunguName
            }
          }
          therapyName
          thumbnailUrl
          discountRate
          discountedPrice
          originalPrice
          applyCount
          categories {
            id
            name
          }
          reviewCnt
          totalRatingCount
          topRatingCount
        }
        pickPoints {
          id
          weeklyPickId
          title
          description
        }
        minPrice
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetPickBanners = gql`
  query ($type: ID) {
    banners(type: $type) {
      id
      link
      image {
        id
        url
        alt
        createdAt
      }
      bannerType {
        id
        name
      }
    }
  }
`;
