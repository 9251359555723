import styled from 'styled-components';
import ProgressiveImage from 'react-progressive-graceful-image';

export default function LazyImage({ src, alt }) {
  return (
    <ProgressiveImage src={src} placeholder={src}>
      {(src) => <Image src={src} alt={alt || ''} />}
    </ProgressiveImage>
  );
}

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-top-left-radius: ${({ isCurve }) => isCurve && '4px'};
  border-top-right-radius: ${({ isCurve }) => isCurve && '4px'};
`;
