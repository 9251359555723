import IntroQuestion from './IntroQuestion';
import Question from './Question';
import PhoneNumberVerification from './PhoneNumberVerification';
import PersonalInformation from './PersonalInformation';
import PaymentMethod from './PaymentMethod';
import PaperDone from './PaperDone';
import CheckIfPossible from './CheckIfPossible';
import AlmostDone from './almostDone';

export default {
  IntroQuestion,
  Question,
  PhoneNumberVerification,
  PersonalInformation,
  PaymentMethod,
  PaperDone,
  CheckIfPossible,
  AlmostDone,
};
