const routeData = [
  {
    label: '필링',
    path: '/philing',
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_01_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_01_selected.png',
    },
    badge: false,
  },
  {
    label: '픽',
    path: '/pick',
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_02_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_02_selected.png',
    },
    badge: false,
  },
  {
    label: '라운지',
    path: '/community',
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_03_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_03_selected.png',
    },
    badge: true,
  },
  {
    label: '마이',
    path: '/my',
    icon: {
      default: 'https://images.odoctor.co.kr/philing/icons/menu_04_default.png',
      active: 'https://images.odoctor.co.kr/philing/icons/menu_04_selected.png',
    },
    badge: false,
  },
];

export default routeData;
