import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_QUESTION_PAPER } from 'graphql/philing';
// import { pixelEvent } from 'utils/pixel';

export default function useQuestionPaperMutation({ CreateQuestionPaperInput, onError }) {
  const option = {
    variables: {
      input: CreateQuestionPaperInput,
    },
    onCompleted: () => {},
    onError: (error) => {
      onError(error.message);
    },
  };

  const [mutate] = useMutation(CREATE_QUESTION_PAPER, option);

  const submitPaper = useCallback(() => {
    return mutate();
  }, [mutate]);

  return [submitPaper];
}
