import styled from 'styled-components';
import Div from 'components/atoms/div';
import { Button } from 'components/atoms/Button';

export const Container = styled.div`
  position: relative;
  font-size: 2rem;
  padding-top: 5.8rem;
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const GiftContent = styled(Div)`
  width: 100%;
`;

export const GiftTitle = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 2rem;
`;

export const GiftCards = styled(Div)`
  margin: 0 -0.5rem;
`;

export const RatioCradWrap = styled(Div)`
  position: relative;
  width: calc(100% - 1rem);
  padding-top: calc(calc(100% - 1rem) * 0.446);
  background-size: contain;
  flex-shrink: 0;
  margin: 0.5rem;
  /* @media (min-width: 550px) {
    width: calc(50% - 1rem);
    padding-top: calc(calc(50% - 1rem) * 0.446);
  } */
`;

export const Card = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 6%;
  .event {
    letter-spacing: 0;
    position: absolute;
    top: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .title {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 3.2rem;
    margin: 0;
  }
  .tag {
    font-size: 1.2rem;
  }
  .coupon-cnt {
    display: flex;
    align-items: center;
    .svg-stamp-black {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.5rem;
    }
    .svg-cross-black {
      width: 1rem;
      height: 1rem;
    }
    span {
      margin-left: 0.2rem;
      font-size: 1.8rem;
      font-weight: 600;
    }
  }
`;

export const CardCouponImage = styled(Div)`
  margin-left: auto;
  width: 28%;
  min-width: 6rem;
  height: 100%;
  background: url(${(props) => props.bg}) no-repeat center center;
  background-size: contain;
  ${(props) => props.state === 'terminated' && 'opacity: .2; filter: grayscale(1);'}
`;

export const CardCouponPriceText = styled(Div)`
  margin-left: auto;
  width: 28%;
  min-width: 6rem;
  height: 100%;
  .price {
    color: #dd4124;
    font-size: 2.4rem;
    font-weight: bold;
  }
  .sub-text {
    font-size: 1.2rem;
    color: #b1b3b2;
    font-weight: 600;
  }
`;

export const CouponTerminated = styled(Div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CardCouponInfo = styled(Div)`
  padding: 2rem 0;
  width: 100%;
`;

export const QuizDescribe = styled(Div)`
  color: #fff;
`;

export const BottomInviteCard = styled(Div)`
  margin: 0 -2rem;
  padding: 2rem 2rem 11.6rem 2rem;
  background-color: #000;
  color: #fff;
  .title {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 3rem 0 1rem;
  }
  .describe {
    font-size: 1.4rem;
  }
`;

export const InviteButton = styled(Button)`
  margin-top: 2rem;
  border-color: #fff;
`;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;
