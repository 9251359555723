import useCountDown from 'hooks/useCountDown';
import React from 'react';

import * as Styled from './index.styles';

export default function CountDown({ endOfOffer }) {
  const { day, hour, minute } = useCountDown(endOfOffer);

  return (
    <Styled.Container>
      <div style={{ fontSize: '2rem' }}>지금 보고 계신 이 혜택</div>
      <Styled.BoldText>
        마감까지 {day > 0 && <Styled.Highlight>{day}일</Styled.Highlight>}
        {day + hour > 0 && (
          <Styled.Highlight style={{ margin: '0 1rem' }}>{hour}시간</Styled.Highlight>
        )}
        <Styled.Highlight>{minute}분</Styled.Highlight>남음
      </Styled.BoldText>
    </Styled.Container>
  );
}
