import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const PointSkeleton = () => <Skeleton height="2.4rem" width="5rem" />;

const SkeletonComponent = () => <div>
  <div
    className="skeleton-wrap"
    style={{
      height: '3rem',
      margin: '9rem 0 3rem',
    }}
  >
    <Skeleton height="100%" width="12rem" /> <Skeleton height="100%" width="10rem" />
  </div>

  <Skeleton height="50rem" width="100%" />
</div>;

export default SkeletonComponent;
