import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { ImageButton } from 'components/atoms/Button';

const borderColor = css`
  &.active {
    border-color: #e1ea68;
  }
  &.error {
    border-color: #db7979;
  }
  &.in-active {
    color: #aaaaaa;
  }
  &.disabled {
    border-color: #ccc;
    background-color: #ccc;
  }
`;

const CardButtonCss = css`
  text-align: left;
  height: 8.2rem;
  padding: 0 2.5rem;
  .cardName {
    font-size: 1.4rem;
  }
  .cardNum {
    font-size: 1.2rem;
  }
`;

export const AnswerButton = styled(ImageButton)`
  font-size: 1.4rem;
  border-radius: 2rem;
  border: 2px solid #ccc;
  width: 100%;
  background-color: #fff;

  height: ${(props) => (props.image ? '7.4rem' : '5.8rem')};
  background-image: ${(props) => `url(${props.image})`};
  ${borderColor}
  ${(props) => props.card && CardButtonCss}
`;

export const AddCardButton = styled.button`
  width: 100%;
  height: 5.8rem;
  background-color: #666;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 2rem;
`;

export const NextButton = styled.button`
  width: 100%;
  height: 6.4rem;
  background-color: #ccc;
  color: #aaa;
  border-radius: 2rem;
  font-size: 1.6rem;
  &.active {
    background-color: #e1ea68;
    color: #000;
  }
`;

export const SkipButton = styled.button`
  color: #666;
  font-size: 1.2rem;
  height: 3rem;
  width: 8rem;
  border-radius: 1.5rem;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 2.6rem;
`;

export const FixedBottom = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 0 -2rem;
  padding: 0 2rem;
  position: absolute;
  bottom: 4rem;
  text-align: center;
  z-index: 10;
`;

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1;
  color: #444;
  margin-bottom: 1.5rem;
`;
export const TimerWrap = styled.div`
  font-size: 1rem;
  color: #db7979;
  font-weight: bold;
`;

export const TextareaWrap = styled.div`
  padding: 2.4rem;
  height: 18rem;
  .msg-error {
    position: absolute;
    right: 0;
    top: -3rem;
    color: #db7979;
    font-size: 1rem;
    display: none;
  }
  &.error {
    .msg-error {
      display: initial;
    }
  }

  .input-group {
    border: none;
    height: 100%;
  }

  textarea {
    height: inherit;
    outline: none;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  position: relative;
  margin: 0 -0.6rem;
  border-radius: 2rem;
  border: 2px solid #ccc;
  background-color: #fff;

  ${borderColor}
`;

const pinWrap = css`
  width: 25%;
  height: initial;
  margin: 0 0.4rem;
  display: flex;
  align-items: center;
  .input-group {
    max-height: 100%;
  }
`;

export const InputWrap = styled.div`
  .msg-error {
    position: absolute;
    right: 0;
    top: -3rem;
    color: #db7979;
    font-size: 1rem;
    display: none;
  }
  &.error {
    .msg-error {
      display: initial;
    }
  }
  &.text-center {
    input {
      text-align: center;
    }
  }

  position: relative;
  margin: 0 -0.6rem;
  height: 5.8rem;
  border-radius: 2rem;
  border: 2px solid #ccc;
  background-color: #fff;
  // 기존 스타일 덮어 씌우기
  .input-group {
    border: none;
    height: 100%;
    max-height: 5.8rem;
  }
  ${borderColor}

  ${(props) => props.pin && pinWrap}
`;

export const CheckboxWrap = styled.div`
  position: relative;
  margin: 0 -0.6rem;
  padding-left: 3rem;
  font-size: 1.2rem;
  span {
    text-decoration: underline;
    font-weight: bold;
  }

  .agree-checkbox {
    opacity: 0;
    position: absolute;
  }

  .agree-checkbox,
  .agree-checkbox-label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  .agree-checkbox + .agree-checkbox-label:before {
    position: absolute;
    left: 0;
    top: -5px;
    content: '';
    background: #fff;
    border-radius: 50%;
    border: 2px solid #ccc;
    width: 2.4rem;
    height: 2.4rem;
    text-align: center;
    box-sizing: border-box;
  }

  .agree-checkbox:checked + .agree-checkbox-label:before {
    border-color: #e1ea68;
  }

  .agree-checkbox + .agree-checkbox-label:after {
    position: absolute;
    left: 9px;
    top: 0px;
    content: '';
    width: 0.4rem;
    height: 1rem;
    border: solid #ccc; // e1ea68
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }

  .agree-checkbox:checked + .agree-checkbox-label:after {
    border-color: #000;
  }
`;
