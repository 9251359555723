import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import * as Styled from './index.style';
import useAllSymptoms from 'hooks/philing/useAllSymptoms';
import useApplyTreatment from 'hooks/philing/useApplyTreatment';
import { setPatientInfo } from 'modules/form/form.actions';
import { confirm } from 'components/molecules/Modal';
import ERROR_MSG from 'config/errorMessage';

export default function PaperDone({ symptomId }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { data } = useAllSymptoms();
  const { PAPER_FORM } = useSelector((state) => state.form);
  const [applyTreat] = useApplyTreatment({
    onComplete: (id) => {
      router.push(`/my/medi/${id}`);
    },
    onError: async (error) => {
      if (
        await confirm({
          text: ERROR_MSG.CREATE_TREATMENT[`${error.message}_TITLE`],
          subText: ERROR_MSG.CREATE_TREATMENT[`${error.message}_TEXT`] || ERROR_MSG.UNKNOWN,
          button: {
            ok: {
              text: '다시 시작하기',
            },
          },
        })
      ) {
        router.push(`/philing/${symptomId}`);
      }
    },
  });

  const ci = [
    {
      label: '이름',
      key: 'name',
    },
    {
      label: '진료항목',
      key: 'department',
    },
    {
      label: '생년월일',
      key: 'birthDate',
    },
    {
      label: '휴대폰번호',
      key: 'phonenum',
    },
  ];

  const handleSubmit = () => {
    const {
      patientInfo: { rrn, name, phonenum, payment },
    } = PAPER_FORM;
    applyTreat({
      medicalDepartmentId: symptomId,
      rrn,
      name,
      phonenum,
      paymentMethodId: payment.id,
    });
  };

  useEffect(() => {
    if (data) {
      const dep = data.medicalDepartments.find((d) => d.id === symptomId);
      dispatch(
        setPatientInfo({
          key: 'PAPER_FORM',
          info: {
            department: dep.name,
          },
        })
      );
    }
  }, [data]);

  return (
    <Styled.Section>
      <Styled.ConfirmInput>
        {ci.map((c, i) => {
          return (
            <React.Fragment key={i}>
              <span>{c.label}</span>
              <span>{PAPER_FORM.patientInfo[c.key]}</span>
            </React.Fragment>
          );
        })}
      </Styled.ConfirmInput>
      <Styled.CautionText style={{ paddingBottom: '14rem' }}>
        사실과 다르거나 허위로 입력하여 무분별하게 약물을 오남용할 경우 심각한 부작용을 겪을 수
        있으며, 이에 대한 책임은 환자 본인에게 있음을 확인하였습니다.
      </Styled.CautionText>
      <Styled.FixedBottom>
        <Styled.NextButton onClick={handleSubmit} className="active">
          진료 신청하기
        </Styled.NextButton>
      </Styled.FixedBottom>
    </Styled.Section>
  );
}
