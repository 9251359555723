import { gql } from '@apollo/client';

export const GetEventsWithHospitalCategory = gql`
  query GetEventsWithHospitalCategory($filter: EventListFilter, $pagination: PaginationInput) {
    events(filter: $filter, pagination: $pagination) {
      data {
        id
        name
        hospital {
          id
          name
        }
        description
        therapyName
        contentPageUrl
        thumbnailUrl
        wideThumbnailUrl
        metaTag {
          id
          title
          description
          keywords
          ogUrl
          ogImage
        }
        startDate
        endDate
        discountRate
        discountedPrice
        originalPrice
        applyCount
        categories {
          id
          name
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
    hospitalCategory {
      id
      name
      description
    }
  }
`;

export const GetEvents = gql`
  query GetEvents {
    events(pagination: { perPage: 9999 }) {
      data {
        id
      }
    }
  }
`;

export const GetEvent = gql`
  query GetEvent($id: ID!) {
    event(id: $id) {
      id
      name
      hospital {
        id
        tel
        name
        address
        introduction
        location {
          id
          siName
          gunguName
        }
        meta {
          cctv {
            enable
            images {
              url
              describe
            }
          }
          preview {
            enable
            youtube
          }
          certi {
            enable
            images {
              url
              describe
            }
          }
          subway {
            enable
            line
            text
          }
          hours {
            enable
            text
          }
          grade
        }
      }
      description
      therapyName
      contentPageUrl
      contentImgUrls
      thumbnailUrl
      wideThumbnailUrl
      priceDescription
      reservationCount
      metaTag {
        id
        title
        description
        keywords
        ogUrl
        ogImage
      }
      startDate
      endDate
      discountRate
      discountedPrice
      originalPrice
      applyCount
      reviewCnt
      totalRatingCount
      topRatingCount
      categories {
        id
        name
      }
      hasAgency
      isDelete
      buttonType
      isEnableCommunity
    }
  }
`;

export const GetEventPackagesWithHospitalCategory = gql`
  query GetEventPackagesWithHospitalCategory(
    $filter: EventPackageListFilter
    $pagination: PaginationInput
  ) {
    eventPackages(filter: $filter, pagination: $pagination) {
      data {
        id
        startDate
        endDate
        name
        description
        contentsImageUrl
        discountRate
        applyCount
        events {
          id
          name
          hospital {
            id
            name
            location {
              id
              siName
              gunguName
            }
          }
          therapyName
          thumbnailUrl
          discountRate
          discountedPrice
          originalPrice
          applyCount
          categories {
            id
            name
          }
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetEventPackage = gql`
  query GetEventPackage($id: ID!) {
    eventPackage(id: $id) {
      id
      startDate
      endDate
      name
      description
      contentsImageUrl
      discountRate
      applyCount
      pickPoints {
        id
        weeklyPickId
        title
        description
      }
      events {
        id
        name
        description
        hospital {
          id
          name
          location {
            id
            siName
            gunguName
          }
        }
        therapyName
        thumbnailUrl
        discountRate
        discountedPrice
        originalPrice
        applyCount
        categories {
          id
          name
        }
      }
    }
  }
`;

export const GetEventWithPackages = gql`
  query GetEventWithPackages($eventId: ID!, $packageId: ID!) {
    event(id: $eventId) {
      id
      name
      hospital {
        id
        name
        location {
          id
          siName
          gunguName
        }
      }
      description
      therapyName
      contentPageUrl
      thumbnailUrl
      wideThumbnailUrl
      metaTag {
        id
        title
        description
        keywords
        ogUrl
        ogImage
      }
      startDate
      endDate
      discountRate
      discountedPrice
      originalPrice
      applyCount
      categories {
        id
        name
      }
    }
    eventPackage(id: $packageId) {
      id
      startDate
      endDate
      name
      description
      contentsImageUrl
      discountRate
      applyCount
      pickPoints {
        id
        weeklyPickId
        title
        description
      }
      events {
        id
        name
        description
        hospital {
          id
          name
          location {
            id
            siName
            gunguName
          }
        }
        therapyName
        thumbnailUrl
        discountRate
        discountedPrice
        originalPrice
        applyCount
        categories {
          id
          name
        }
      }
    }
  }
`;

export const ApplyEvent = gql`
  mutation ApplyEvent($input: CreateEventReservationInput!) {
    createEventReservation(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const ApplyPackages = gql`
  mutation ApplyPackages($input: CreateEventPackageReservationInput!) {
    createEventPackageReservation(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;
