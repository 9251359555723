import React, { useState } from 'react';
import * as Styled from './index.styles';
import { PhotoUploadBox } from 'components/molecules/PhotoS3';
import { hashDate } from 'utils/crypto';
import { confirm } from 'components/molecules/Modal';

let uploadList = [];

const mdNewFileFromBlob = (file) => {
  var blob = file.slice(0, file.size, 'image/jpg');
  return new File(
    [blob],
    `${file.name.replace(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i, '')}.${hashDate()}.jpg`,
    {
      type: 'image/jpg',
    }
  );
};

export const PhotoUploader = ({ bucket, path, onInit, onFinal, maxLength = 4 }) => {
  const [photos, setPhotos] = useState([]);

  const showModal = (text) => {
    confirm({
      icon: '💬',
      // title: '타이틀이랍니다',
      text,
      // link: {
      //   text: '링크랍니다',
      //   url: 'https://odoctor.co.kr'
      // }
      button: {
        cancel: false,
        ok: {
          text: '확인',
          callback: () => {
            // alert('교환')
          },
        },
      },
    });
  };

  const onChange = (e) => {
    const fileList = e.target.files;
    const nextPhotos = [
      ...photos,
      ...[...fileList].map((_f) => {
        return {
          s: false,
          file: mdNewFileFromBlob(_f),
        };
      }),
    ];
    if (nextPhotos.length > maxLength) {
      showModal(`이미지는 최대 ${maxLength}개까지만\n등록 가능합니다.`);
      return;
    }

    onInit && onInit();
    setPhotos(nextPhotos);
  };

  const checkUpload = (photoArray) => {
    if (uploadList.filter((_) => _?.s === true).length === photoArray.length) {
      onFinal && onFinal(uploadList);
    }
  };

  const onRemovePhoto = (index) => {
    uploadList = uploadList.filter((_, i) => i !== index);
    const nextPhotos = photos.filter((_, i) => i !== index);
    setPhotos(nextPhotos);
    checkUpload(nextPhotos);
  };

  return (
    <>
      <div>
        <label htmlFor="file">
          <Styled.PhotoAddButton {...(photos.length === maxLength && { disabled: true })}>
            사진 추가하기 ({photos.length}/{maxLength}) +
          </Styled.PhotoAddButton>
        </label>
        <div>
          <input
            id="file"
            type="file"
            multiple
            onChange={onChange}
            style={{ display: 'none' }}
            {...(photos.length === maxLength && { disabled: true })}
          />
        </div>
      </div>
      <Styled.PhotoWrapper>
        {photos.map((p, idx) => {
          return (
            <div key={idx} className="wrapper">
              <PhotoUploadBox
                key={idx}
                file={p.file}
                path={path}
                onUploadSuccess={() => {
                  uploadList[idx] = { file: p.file.name, s: true };
                  checkUpload(photos);
                }}
                onRemove={() => {
                  onRemovePhoto(idx);
                }}
                onError={() => {
                  alert('이미지 업로드중 오류가 발생했습니다. 다시 시도해 주세요.');
                  onRemovePhoto(idx);
                }}
              />
            </div>
          );
        })}
      </Styled.PhotoWrapper>
    </>
  );
};
