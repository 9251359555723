import styled, { css } from 'styled-components';

const bgLogoCSS = css`
  background: url('/assets/images/icons/main_logo_02.svg') no-repeat center;
  background-size: 3.4rem;
`;
export const Container = styled.div`
  background-color: #fff;
  position: sticky;
  top: 0;
  width: 100%;
  height: 6rem;
  display: grid;
  grid-template-columns: 6rem auto 6rem;
  /* margin: 0 -1.4rem; */
  /* div:nth-child(2) {
    grid-template-columns: repeat(5, minmax(1rem, auto));
    grid-column: span 3;
  } */
`;

export const Wrapper = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderButton = styled.button`
  background-color: transparent;
  width: 100%;
  height: 100%;
  ${(props) => props.logo && bgLogoCSS}
`;
