import Compressor from 'compressorjs';

export const imageCompress = ({ file, onSuccess }) => {
  new Compressor(file, {
    quality: 0.7, // Recommend [0.6~0.8]
    width: 700,
    maxHeight: 1400,

    // The compression process is asynchronous,
    // which means you have to access the `result` in the `success` hook function.
    success(result) {
      var compressedFile = new File([result], 'my_image.png', {
        type: 'image/png',
        lastModified: new Date().getTime(),
      });

      if (onSuccess) onSuccess(compressedFile);
      // download and check
      // var a = document.getElementById('a');
      // a.href = URL.createObjectURL(file);
      // a.download = name;

      // Send the compressed image file to server with XMLHttpRequest.
      // axios.post('/path/to/upload', formData).then(() => {
      //   console.log('Upload success');
      // });
    },
    error(err) {
      console.log(err.message);
    },
  });
};
