import { PaperInput } from 'components';
import * as Styled from './index.style';
import React, { useState, useEffect } from 'react';
import { isBirth } from 'utils/validate';
import { getAge } from 'utils/date';
import { isBetween } from 'utils/validate';
import { useUser } from 'hooks/useUser';
import { GENDER_GROUP } from 'utils/constants';
import { useRouter } from 'next/router';
import useSymptom from 'hooks/philing/useSymptom';

const PaperSelectButton = ({ label, data, selectedValue, onClick }) => {
  return (
    <div>
      {label && <Styled.Label>{label}</Styled.Label>}
      <Styled.SelectWrap>
        {data.map((o, i) => {
          const className = !selectedValue
            ? ''
            : selectedValue === o.value
            ? 'active'
            : 'in-active';
          return (
            <Styled.Button
              key={i}
              onClick={() => {
                onClick(o.value);
              }}
              type={'button'}
              className={`${className}`}
            >
              {o.label}
            </Styled.Button>
          );
        })}
      </Styled.SelectWrap>
    </div>
  );
};

const CheckIfPossible = ({ symptomId, saveAnswer }) => {
  const router = useRouter();
  const [gender, setGender] = useState(0);
  const [birthDate, setBirthDate] = useState();
  const [disable, setDisable] = useState(true);
  const { user } = useUser();
  const { data } = useSymptom({ symptomId });
  // 나이와 성별이 문진 조건과 맞는지 check

  const errorHandler = () => {
    router.push(`/philing/${symptomId}/error`);
  };

  const validation = () => {
    const {
      medicalDepartment: { ageLimit, genderLimit },
    } = data;

    const userAge = getAge(birthDate);

    if (!isBetween(userAge, ageLimit.min, ageLimit.max)) {
      errorHandler();
      return false;
    }

    if (!(genderLimit & gender)) {
      errorHandler();
      return false;
    }
    return true;
  };

  useEffect(() => {
    // todo Redux -> 어차피 gender와 birth 값을 redux에 저장한다
    setDisable(!(isBirth(birthDate) && gender));

    return gender;
  }, [birthDate, gender]);

  const handleSubmit = () => {
    if (!validation()) return;
    saveAnswer({
      response: `${birthDate}, ${gender}`,
      info: {
        birthDate: birthDate,
        gender: gender,
      },
    });
  };

  return (
    <div>
      <div style={{ paddingBottom: '14rem' }}>
        <Styled.Section>
          <PaperInput
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="8자리 (20020531)"
            label={'생년월일'}
            maxLength={8}
            initValue={user?.birth || null}
            errorMsg="생년월일을 올바르게 입력해주세요"
            validate={(inputValue) => {
              return isBirth(inputValue);
            }}
            onChange={(inputValue) => {
              const birth = isBirth(inputValue) && inputValue;
              setBirthDate(birth);
            }}
          />
        </Styled.Section>
        <Styled.Section>
          <PaperSelectButton
            label={'성별'}
            data={GENDER_GROUP}
            selectedValue={gender}
            onClick={(value) => {
              setGender(value);
            }}
          />
        </Styled.Section>
      </div>
      <Styled.FixedBottom>
        <Styled.NextButton
          className={`${disable ? '' : 'active'}`}
          disabled={disable}
          onClick={handleSubmit}
        >
          다음
        </Styled.NextButton>
      </Styled.FixedBottom>
    </div>
  );
};

export default CheckIfPossible;
