import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import USER_QUERY from 'graphql/user';

/**
 * 핸드폰 번호 중복 검사
 */
export const useCheckDuplicate = ({ onCompleted, onError }) => {
  const option = {
    onError: (error) => {
      onError(error);
    },
    onCompleted: (response) => {
      const data = response.checkPhonenum;
      if (data.ok) {
        onCompleted();
      }
    },
  };

  // }

  const [mutate, { loading, error }] = useMutation(USER_QUERY.IS_USER_PHONE, option);

  const checkPhoneDuplicate = useCallback(
    (phonenum) => {
      mutate({
        variables: {
          phonenum: phonenum,
        },
      });
    },
    [mutate]
  );

  return [checkPhoneDuplicate, loading, error];
};
