import styled from 'styled-components';
import Div from 'components/atoms/div';

export const Container = styled.div`
  position: relative;
  padding: 11.8rem 0 12rem;
  b {
    font-weight: bold;
  }
`;

export const TermsWrap = styled.div`
  font-size: 1.4rem;
  white-space: pre-wrap;
  word-break: break-all;
  text-align: left;
  b {
    font-weight: bold;
    line-height: 2.5;
  }
`;

export const TermsHeaderWrap = styled(Div)`
  width: 100%;
  max-width: 420px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
`;

export const TermsHeader = styled(Div)`
  height: 58px;
  .svg-button-close-cross {
    padding: 10px;
    margin: -10px;
    background-position: center;
  }
`;

export const TermsTitleWrap = styled.div`
  margin-bottom: 8rem;
  .svg-logo-odoctor {
    margin-bottom: 2rem;
  }
`;

export const TermsTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.3;
`;

export const TermsDescription = styled.div`
  margin-top: 0.5rem;
  font-size: 1.4rem;
`;
