import styled from 'styled-components';
import Div from 'components/atoms/div';

export const Container = styled.div`
  position: relative;
  font-size: 2rem;
  padding: 5.8rem 0 12rem;
`;

export const Header = styled(Div)`
  margin: 0 -2rem;
  position: absolute;
  top: 0;
  color: #000;
  height: 5.8rem;
`;

export const ItemContent = styled(Div)``;
export const ItemImageWrap = styled(Div)`
  position: relative;
  margin: 0 -2rem;
  background-color: #e3e3e3;
`;

export const ItemImage = styled(Div)`
  width: 100%;
  padding-top: 100%;
  position: relative;
  .item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.itemImg}) no-repeat center center;
    background-size: cover;
  }
  .skeleton-wrap {
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ItemDescription = styled(Div)`
  padding: 2rem 0 8rem;
  font-size: 1.4rem;
  pre {
    white-space: pre-wrap;
  }
`;

export const ItemTitle = styled(Div)`
  font-size: 2.4rem;
  line-height: 1.3;
`;

export const ItemStampCost = styled(Div)`
  font-weight: 600;
  font-size: 2.4rem;
  color: #dd4124;
`;

export const ItemNotice = styled(Div)`
  margin-top: 6rem;
  .due-date {
  }
  .cautions {
    margin-top: 2.8rem;
  }
  .title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .describe {
    line-height: 1.7;
  }
`;

export const FixedBottom = styled(Div)`
  width: 100%;
  max-width: 420px;
  position: fixed;
  margin-left: -2rem;
  bottom: 0;
  text-align: center;
  button {
    color: #fff;
    background-color: #000;
    font-size: 1.4rem;
    height: 6.4rem;
  }
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 0.8rem;
`;

export const StampTitle = styled.div`
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 1.2;
`;

export const StampTitleDescribe = styled(Div)`
  font-size: 1.4rem;
  color: #000;
  line-height: 1.4;
  margin-bottom: 2rem;
`;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;
