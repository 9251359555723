/**
 * bold - $b
 * newLine - %n
 *
 * @type {string}
 */

export const text = `오닥터(이하 ‘회사’)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 개인정보처리방침을 두고 있습니다.
회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

$b제1조 개인정보의 수집목적$b
회사는 개인정보를 다음의 목적을 위해 수집합니다. 수집한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
1. 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 인증, 회원자격 유지 관리, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지 통지, 고충처리, 분쟁 조정을 위한 기록 보존, 파트너병원과 연계하여 맞춤형 상담서비스 및 예약정보 제공
2. 서비스 제공, 기능 개선, 연구 개발
서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 제공, 결제, 물품 또는 경품 배송, 본인인증 및 연령확인, 자료분석, 어플리케이션 기능 개선, 기술 개발 및 신규 서비스 출시
3. 마케팅 및 광고 활용
신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계

$b제2조 수집항목 및 수집방법$b
1. 수집항목
1) 회원가입 시 수집 정보
필수: ID(이메일 주소), 비밀번호, 이름
선택: 휴대폰번호, 성별, 출생년도, 시술 및 치료 전후 사진, 주소
2) 비회원 수집 정보 (별도 동의 시)
이름, 휴대폰번호, 성별
3) 서비스 이용 과정에서 생성, 수집되는 정보
IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 쿠키
2. 수집방법
1) 당사 홈페이지, 모바일 앱/웹
가입, 본인인증, 후기 및 평가 작성, 이벤트 및 상담 신청, 경품 신청
2) 서비스 이용 과정에서 생성되는 정보 수집
3) 쿠키에 의한 정보 수집

$b제3조 개인정보의 처리 및 보유 기간$b
회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다. 각각의 개인정보 처리⋅보유 기간은 다음과 같습니다.
1. 회원 가입 및 관리
상거래관계 종료시로부터 5년이 경과한 시점까지 보유하되, 그 이후에도 관계 법령 위반에 따른 수사⋅ 조사 등이 진행중인 경우에는 해당 수사⋅조사 종료시까지
2. 서비스 제공
상거래관계 종료시로부터 5년이 경과한 시점까지 보유하되, 그 이후에도 관계 법령 위반에 따른 수사⋅ 조사 등이 진행중인 경우에는 해당 수사⋅조사 종료시까지
단, 다음의 사유에 해당하는 경우에는 상거래관계 종료시로부터 5년이 경과하더라도 해당 기간 종료시까지 보유합니다.
1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시⋅광고, 계약내용 및 이행 등 거래에 관한 기록
- 표시⋅광고에 관한 기록 : 6개월
- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
2) 「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
- 가입자 전기통신일시, 개시⋅종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년
- 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
3) 「상법」제33조에 따른 상업장부 등의 보관
- 상업장부 및 영업에 관한 중요서류: 10년
- 전표 또는 이와 유사한 서류: 5년
3. 마케팅 : 서비스 회원 탈퇴시 또는 동의 철회시까지

$b제4조 개인정보 제3자 제공$b
회사는 이용자의 개인정보를 「1. 개인정보의 수집목적」에서 고지한 범위 내에서 사용하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
제공받는 자 - 이용자가 상담 및 예약을 신청한 병원 
제공 개인정보 - 이름, 휴대폰번호, 성별, 생년월일, 상담내용, 구강 및 안면사진
이용목적 - 고객 상담 전화 
제공기간 - 1개월

$b제5조 개인정보처리 위탁$b
1. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
[수탁자: 위탁업무]
네이버, 카카오, 페이스북: SNS 가입 여부 및 계정 정보 확인
Toast, 컴홈, ㈜카카오: SMS 및 메시지 발송
Amazon Web Services Inc.: 데이터 보관
Microsoft: 이메일, 문서/파일 저장과 내부 커뮤니케이션 및 협업 도구
케이티엠하우스: 쿠폰의 발행 및 사용
2. 회사는 위탁계약 체결 시 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 및 개인정보 보호법에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

$b제6조 이용자 및 법정대리인의 권리와 그 행사방법$b
1. 이용자는 회사에 대해 언제든지 개인정보 열람⋅정정⋅삭제⋅처리정지 요구 등의 권리를 행사할 수 있습니다.
2. 제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면, 전화, 전자우편 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
3. 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
4. 이용자의 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 제한될 수 있습니다.
5. 다른 법령에서 수집 대상으로 명시되어 있는 개인정보의 경우에는 그 삭제를 요구할 수 없습니다.
6. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

$b제7조 개인정보의 파기$b 
회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기하며, 파기의 절차와 및 방법은 다음과 같습니다.
1. 파기절차
이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
2. 파기방법
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

$b제8조 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항$b
1. 쿠키의 정의
1) 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
2) 쿠키는 웹사이트를 운영하는 데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 디바이스의 저장장치에 저장됩니다.
2. 회사의 쿠키 사용 목적
이용자들이 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 상품정보, 이용자 규모 등을 파악하여 이용자에게 광고를 포함한 최적화된 맞춤형 정보를 제공하기 위해 쿠키를 사용합니다.
3. 쿠키의 설치∙운영 및 거부
1) 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다.
2) 쿠키의 설치, 운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 
3) 이용자가 쿠키의 저장을 거부할 경우에는 개인 맞춤서비스 등 회사가 제공하는 일부 서비스 이용에 어려움이 있을 수 있습니다.

$b제10조 개인정보 보호책임자$b
이용자는 회사의 서비스를 이용하는 과정에서 발생하는 모든 개인정보보호 관련 민원을 아래의 개인정보 보호책임자에게 신고하실 수 있으며, 회사는 이용자의 신고사항에 대해 신속하고 성실하게 답변해드리고 있습니다.
[개인정보보호 책임자]
이름: 고석호
소속: 경영마케팅전략부
전화: 02-3443-1357
메일: cs@odoctor.co.kr

$b제11조 개인정보처리방침 변경$b
이 개인정보처리방침은 2020년 9월 10일부터 시행되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다. 

$b제12조 개인정보의 안전성 확보 조치$b
회사는 개인정보 보호법 및 정보통신망 이용촉진 및 정보 보호 등에 관한 법률 및 GDPR 제32조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
1. 정기적인 자체 감사 실시
개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
2. 개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
3. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다. 
4. 해킹 등에 대비한 기술적 대책
회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다
5. 개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
6.접속기록의 보관 및 위조 • 변조 방지
개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
7. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
8.문서보안을 위한 잠금장치 사용
개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
9. 비인가자에 대한 출입 통제
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
`;
