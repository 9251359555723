import { gql } from '@apollo/client';

export const GET_SIGN_URL = gql`
  mutation($filename: String!) {
    signS3(filename: $filename, filetype: "image") {
      signedRequest
      url
    }
  }
`;
