const CREATE_TREATMENT_ERROR_MSG = {
  TITLE: '문진 답변 오류',
  ONGOING_TREATMENT_EXIST_TITLE: '문진 답변 오류',
  ONGOING_TREATMENT_EXIST_TEXT: '이미 진행중인 진료가 존재 합니다.\n문진을 다시 시작해 주세요.',
  INVALID_RRN_TITLE: '문진 답변 오류',
  INVALID_RRN_TEXT: '올바른 주민번호가 아닙니다.\n문진을 다시 시작해 주세요.',
  INVALID_TREATMENT_AGE_LIMIT_TITLE: '신청정보를 확인해주세요.',
  INVALID_TREATMENT_AGE_LIMIT_TEXT:
    '입력하신 정보와 과거 진료기록의 성별/생년월이 일치하지 않습니다. 본인만 진료신청이 가능하니,\n신청정보를 확인 후 다시 입력해주세요.',
  INVALID_TREATMENT_GENDER_LIMIT_TITLE: '신청정보를 확인해주세요.',
  INVALID_TREATMENT_GENDER_LIMIT_TEXT:
    '입력하신 정보와 과거 진료기록의 성별/생년월이 일치하지 않습니다. 본인만 진료신청이 가능하니,\n신청정보를 확인 후 다시 입력해주세요.',
  INVALID_MEDICAL_PAPER_TITLE: '제출하신 문진이 잘못 되었습니다.\n문진을 다시 시작해 주세요.',
  INVALID_MEDICAL_PAPER_TEXT: '제출하신 문진이 잘못 되었습니다.\n문진을 다시 시작해 주세요.',
  INVALID_PAYMENT_METHOD_TITLE: '결제 수단이 잘못 되었습니다.\n문진을 다시 시작해 주세요.',
  INVALID_PAYMENT_METHOD_TEXT: '결제 수단이 잘못 되었습니다.\n문진을 다시 시작해 주세요.',
};

const CANCEL_TREATMENT_ERROR_MSG = {
  INVALID_TREATMENT: '상태 맞지않음, 유저가 틀리거나, 아이디가 없음',
};

const CANCEL_TREATMENT_MATCHING_ERROR_MSG = {
  INVALID_TREATMENT: '진료 정보가 올바르지 않습니다.',
  MATCHING_CANCEL_MAXIMUM_NUM_EXCEEDED: '거부는 두번까지만 가능',
};

const SUBMIT_PHARMACY_INFORMATION_ERROR_MSG = {
  INVALID_TREATMENT: '진료 정보가 올바르지 않습니다.',
  NOT_FOUND_PRESCRIPTION: '처방전이 존재 하지 않습니다.',
  PRESCRIPTION_EXPIRED: '만료된 처방전 입니다.',
  HOSPITAL_CARE_PAYMENT_REQUIRED: '',
  INVALID_PHARMACY: '존재하지 않는 약국입니다.',
  PHARMACY_FAX_NOT_FOUND: '해당 약국에 팩스를 전송 할 수 없습니다.',
  FAX_SEND_FAIL: '팩스 전송에 실패했습니다.',
  INVALID_ADDRESS: '잘못된 주소입니다.',
  REQUIRED_DELIVERY_INFORMATION: '배송지 정보를 입력해 주세요.',
};

const CREATE_PHARMACY_CARE_PAYMENT_ERROR_MSG = {
  INVALID_TREATMENT: '진료 정보가 올바르지 않습니다.',
  PRESCRIPTION_EXPIRED: '만료된 처방전 입니다.',
  INVALID_ORDER: '잘못된 결제건 입니다.',
  ALREADY_PAID: '이미 결제가 되었습니다.',
  INVALID_PAYMENT_METHOD: '잘못된 결제수단입니다.',
  PAYMENT_FAIL: '결제가 실패했습니다.',
};

const UPDATE_PHARMACY_INFORMATION_ERROR_MSG = {
  INVALID_TREATMENT: '진료 정보가 올바르지 않습니다.',
  NOT_FOUND_PRESCRIPTION: '처방전이 존재 하지 않습니다.',
  PRESCRIPTION_EXPIRED: '만료된 처방전 입니다.',
  INVALID_PHARMACY: '존재하지 않는 약국입니다.',
  PHARMACY_FAX_NOT_FOUND: '해당 약국에 팩스를 전송 할 수 없습니다.',
  FAX_SEND_FAIL: '팩스 전송에 실패했습니다.',
};

const COMPLETE_TREATMENT_ERROR_MSG = {
  INVALID_TREATMENT: '진료 정보가 올바르지 않습니다.',
  DELIVERY_NOT_FOUND: '배송지 정보가 존재하지 않습니다.',
};

const UNKNOWN_ERROR_MSG = '알수 없는 오류가 발생했습니다.\n문진을 다시 시작해 주세요.';

const CREATE_QUESTION_PAPER_ERROR_MSG = {
  REQUIRED_QUESTION: {
    TITLE: '문진 답변 오류.\n처음 부터 다시 시작해 주세요',
  },
  ONGOING_TREATMENT_EXIST: {
    TITLE: '정확한 진료와\n약의 오남용을막기 위해\n반복된 진료 신청을 제한합니다.',
    TEXT: '진료가 끝나고 일주일 뒤에 다시 신청해주세요.',
  },

  INVALID_TREATMENT: '진료 아이디 오류',
  TREATMENT_QUESTION_PAPER_ALREADY_EXIST: '이전에 작성한 문진이 있습니다.',
  INVALID_DEPARTMENT: '올바른 진료 과목이 아닙니다',
  INVALID_TREATMENT_STATUS: {
    TITLE: '진료가 취소되거나 종료되어\n문진을 등록할 수 없습니다.',
    TEXT: null,
  },
  // '진료가 취소되거나 종료되어 문진을 등록할 수 없습니다.',
  INVALID_MEDICAL_PAPER_ANSWER: '답변 오류',
};

const SUBMIT_QUESTION_ANSWER_ERROR_MSG = {
  INVALID_TREATMENT: '진료 아이디 오류',
  TREATMENT_QUESTION_PAPER_ALREADY_EXIST: '이전에 작성한 문진이 있습니다.',
  INVALID_DEPARTMENT: '올바른 진료 과목이 아닙니다',
  INVALID_TREATMENT_STATUS: '진료가 취소되거나 종료되어\n문진을 등록할 수 없습니다.',
  INVALID_MEDICAL_PAPER_ANSWER: '답변 오류',
};

export default {
  CREATE_TREATMENT: CREATE_TREATMENT_ERROR_MSG,
  CANCEL_TREATMENT: CANCEL_TREATMENT_ERROR_MSG,
  CANCEL_TREATMENT_MATCHING: CANCEL_TREATMENT_MATCHING_ERROR_MSG,
  SUBMIT_PHARMACY_INFORMATION: SUBMIT_PHARMACY_INFORMATION_ERROR_MSG,
  CREATE_PHARMACY_CARE_PAYMENT: CREATE_PHARMACY_CARE_PAYMENT_ERROR_MSG,
  UPDATE_PHARMACY_INFORMATION: UPDATE_PHARMACY_INFORMATION_ERROR_MSG,
  COMPLETE_TREATMENT: COMPLETE_TREATMENT_ERROR_MSG,
  UNKNOWN: UNKNOWN_ERROR_MSG,
  CREATE_QUESTION_PAPER: CREATE_QUESTION_PAPER_ERROR_MSG,
  SUBMIT_QUESTION_ANSWER: SUBMIT_QUESTION_ANSWER_ERROR_MSG,
};
