import React, { useCallback } from 'react';
import { useRouter } from 'next/router';

import * as Styled from './index.styles';
import { createUrlWithQuery } from 'utils/createUrlWithQuery';

export default function Navigator() {
  const router = useRouter();

  const handleClick = useCallback(
    (to) => () => {
      setTimeout(() => {
        router.push(createUrlWithQuery({ query: router.query, url: to }));
      }, 100);
    },
    []
  );

  const handlePath = (path) => {
    return router.pathname === path;
  };

  const picks = ['/pick/all', '/pick/special', '/pick/weekly'];

  return (
    <Styled.Container>
      <Styled.Box
        className="dock_home"
        isActive={router.pathname === '/'}
        onClick={handleClick('/')}
      >
        <Styled.IconWrapper>
          <div className={router.pathname === '/' ? 'svg-home-active' : 'svg-home'} />
        </Styled.IconWrapper>
        <Styled.Name>홈</Styled.Name>
      </Styled.Box>
      <Styled.Box
        className="dock_pick"
        isActive={router.pathname.startsWith('/pick')}
        onClick={handleClick('/pick')}
      >
        <Styled.IconWrapper>
          <div className={router.pathname.startsWith('/pick') ? 'svg-pick-active' : 'svg-pick'} />
        </Styled.IconWrapper>
        <Styled.Name>픽</Styled.Name>
      </Styled.Box>
      <Styled.Box
        className="dock_quiz"
        isActive={router.pathname === '/quiz'}
        onClick={handleClick('/quiz')}
      >
        <Styled.IconWrapper>
          <div className={router.pathname === '/quiz' ? 'svg-quiz-active' : 'svg-quiz'} />
        </Styled.IconWrapper>
        <Styled.Name>오감</Styled.Name>
      </Styled.Box>
      <Styled.Box
        className="dock_mypage"
        isActive={router.pathname === '/my'}
        onClick={handleClick('/my')}
      >
        <Styled.IconWrapper>
          <div className={router.pathname === '/my' ? 'svg-my-active' : 'svg-my'} />
        </Styled.IconWrapper>
        <Styled.Name>마이</Styled.Name>
      </Styled.Box>
    </Styled.Container>
  );
}
