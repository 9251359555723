import React from 'react';
import { useRouter } from 'next/router';
import * as Styled from './index.styles';

const GiftListMain = ({ rewardItem = [] }) => {
  const router = useRouter();

  return (
    <>
      <Styled.GiftContent>
        {/* <Styled.GiftTitle>
          Gift
        </Styled.GiftTitle> */}
        <Styled.GiftCards d-flex flex-wrap>
          {rewardItem.map((item, idx) => (
            <Styled.RatioCradWrap key={idx} className="svg-card-outline">
              <Styled.Card
                d-flex
                justify-content-between
                onClick={() => {
                  router.push({
                    pathname: `/my/reward/${item.id}`,
                  });
                }}
              >
                <Styled.CardCouponInfo d-flex flex-column justify-content-between>
                  <div>
                    <div className="title">
                      {item.itemBrandName} {item.itemName}
                    </div>
                    <div className="tag">{item.metadata.tag}</div>
                  </div>
                  <div className="coupon-cnt">
                    {/* <div className="svg-stamp-black" />
                    <div className="svg-cross-black" />
                    <span>{Math.floor(item.itemPoint / 100)}</span> */}
                    <span>{item.itemPoint}P</span>
                  </div>
                </Styled.CardCouponInfo>
                <Styled.CardCouponImage state={item.itemState} bg={item.itemBrandImg} />
              </Styled.Card>
              {item.itemState === 'terminated' && (
                <Styled.CouponTerminated className="svg-coupon-terminated" />
              )}
            </Styled.RatioCradWrap>
          ))}
        </Styled.GiftCards>
      </Styled.GiftContent>

      {/* <Styled.BottomInviteCard>
      <div className="emoji" />
      <div>
        <div className="title">
          친구 초대하고 건강챙기기
        </div>
        <div className="describe">
          초대한 링크를 통해 친구가 가입하고
          <br />
          휴대폰 인증하면 60P!
        </div>
        <div>
          <Styled.InviteButton
            onClick={() => {
              webShare({
                referralCode: user.referralCode,
              });
            }}
          >
            친구 초대하기
          </Styled.InviteButton>
        </div>
      </div>
    </Styled.BottomInviteCard> */}
    </>
  );
};

export default GiftListMain;
