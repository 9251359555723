import React, { useCallback } from 'react';
import { useRouter } from 'next/router';

import * as Styled from './CardSelectable.styles';
import LazyImage from '../../atoms/LazyImage';
import numberWithCommas from 'utils/numberWithCommas';
import { createUrlWithQuery } from 'utils/createUrlWithQuery';
import { pixelEvent } from 'utils/pixel';

export default function CardSelectable({
  description,
  discountRate,
  discountedPrice,
  hospital,
  id: eventId,
  originalPrice,
  therapyName,
  thumbnailUrl,
  selected,
  packageId,
  handleSelect,
}) {
  const router = useRouter();
  const eventUrl = createUrlWithQuery({
    query: router.query,
    url: `/pick/package/${packageId}/${eventId}`,
  });

  const onClickButtonDetail = useCallback(
    (e) => {
      e.stopPropagation();
      pixelEvent('ViewContent', {
        content_type: 'weeklyDetail',
        content_ids: eventId,
        value: packageId,
        product: therapyName,
      });
      router.push(eventUrl);
    },
    [eventUrl]
  );

  return (
    <Styled.Container onClick={handleSelect(eventId)}>
      <Styled.Header>
        <Styled.ImageWrapper>
          <LazyImage src={thumbnailUrl} alt={therapyName} />
        </Styled.ImageWrapper>
        <Styled.InfoRow>
          <Styled.Title>{therapyName}</Styled.Title>
          <Styled.PriceWrapper>
            <Styled.Row>
              <RenderPrice prices={[discountRate, discountedPrice, originalPrice]} />
            </Styled.Row>
          </Styled.PriceWrapper>
        </Styled.InfoRow>
      </Styled.Header>
      <Styled.Body>
        <Styled.InfoWrapper>
          <Styled.Address>{hospital.name}</Styled.Address>
          <Styled.Dot />
          <Styled.Address>{`${hospital.location?.siName} ${hospital.location?.gunguName}`}</Styled.Address>
        </Styled.InfoWrapper>
        <Styled.Desc>{description}</Styled.Desc>
        <Styled.ButtonWrap>
          <Styled.Button onClick={() => router.push(`/pick/${eventId}`)}>자세히보기</Styled.Button>
        </Styled.ButtonWrap>
      </Styled.Body>
    </Styled.Container>
  );
}

const RenderPrice = ({ prices }) => {
  const [discountRate, discountedPrice, originalPrice] = prices;
  const hasAll = prices.every((price) => !!price);

  if (hasAll) {
    // 전부
    return (
      <>
        <Styled.DiscountRate>{discountRate}%</Styled.DiscountRate>
        <Styled.Price>{numberWithCommas(discountedPrice)}원</Styled.Price>
        <Styled.Origin>{numberWithCommas(originalPrice)}원</Styled.Origin>
      </>
    );
  } else if (discountRate) {
    // 할인율만
    return (
      <>
        <Styled.DiscountRate>{discountRate}% 할인</Styled.DiscountRate>
      </>
    );
  } else {
    // 할인가만
    return (
      <>
        <Styled.Price>{numberWithCommas(discountedPrice)}원</Styled.Price>
      </>
    );
  }
};
