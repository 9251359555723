/*
 * Using ref -> https://reactjs.org/docs/refs-and-the-dom.html
 */

import React from 'react';
import styled, { css } from 'styled-components';

const WrapperStyle = {
  'd-flex': 'display: flex;',
  'flex-row': 'flex-direction: row;',
  'flex-column': 'flex-direction: column;',
  'flex-fill': 'flex: 1 1 auto;',
  'justify-content-start': 'justify-content: start;',
  'justify-content-stretch': 'justify-content: stretch;',
  'justify-content-end': 'justify-content: end;',
  'justify-content-center': 'justify-content: center;',
  'justify-content-between': 'justify-content: space-between;',
  'justify-content-around': 'justify-content: space-around;',
  'align-items-start': 'align-items: start;',
  'align-items-end': 'align-items: end;',
  'align-items-center': 'align-items: center;',
  'align-items-baseline': 'align-items: baseline;',
  'align-items-stretch': 'align-items: stretch;',
  'align-self-start': 'align-self: start',
  'align-self-end': 'align-self: end',
  'align-self-center': 'align-self: center',
  'align-self-baseline': 'align-self: baseline',
  'align-self-stretch': 'align-self: stretch',
  'flex-wrap': 'flex-wrap: wrap;',
  'flex-nowrap': 'flex-wrap: nowrap;',
  'position-static': 'position: static;',
  'position-absolute': 'position: absolute;',
  'position-relative': 'position: relative;',
  'position-fixed': 'position: fixed;',
  'position-sticky': 'position: sticky;',
  border: 'border: solid 1px #e1e3e2;',
  'border-round': 'border-radius: 4px;',
  active: 'border-color: #000 !important',
};

const style = {
  ...WrapperStyle,
};

const CSS = (props) => {
  let styles = '';

  for (const [key] of Object.entries(props)) {
    if (style[key]) {
      styles += `
       ${style[key]}
     `;
    }
  }

  return css`
    ${styles}
  `;
};

const Wrapper = styled.div`
  height: 5.8rem;
  border: solid 1px #e1e3e2;

  ${CSS};
  input {
    font-size: 1.4rem;
    width: 100%;
    height: 100%;
    padding: 0 1.4rem;
    background-color: transparent;
  }

  textarea {
    font-size: 1.4rem;
    width: 100%;
    background-color: transparent;
    border: none;
    resize: none;
    overflow: hidden;
  }
`;

export const Input = ({ className, value, name, type, onChange, placeholder, ...other }) => {
  const textInput = React.createRef();

  const handleChange = () => {
    onChange(textInput.current.value);
  };

  return (
    <input
      className={className}
      value={value}
      placeholder={placeholder}
      name={name || 'inputText'}
      type={type || 'text'}
      ref={textInput}
      {...other}
      {...(onChange && { onChange: handleChange })}
    />
  );
};

export const InputRef = ({ className, inputRef, name, type, onChange, ...others }) => (
  <Wrapper className="input-group" {...others}>
    <input
      className={className}
      name={name || 'inputText'}
      type={type || 'text'}
      ref={inputRef}
      {...(onChange && { onChange })}
      {...others}
    />
  </Wrapper>
);

export const TextAreaRef = ({ className, inputRef, name, type, onChange, ...others }) => (
  <Wrapper className="input-group">
    <textarea
      className={className}
      name={name || 'inputText'}
      type={type || 'text'}
      ref={inputRef}
      {...(onChange && { onChange })}
      {...others}
    />
  </Wrapper>
);
