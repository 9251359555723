import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import USER_QUERY from 'graphql/user';
import Div from 'components/atoms/div';
import { Button, OutlineButton } from 'components/atoms/Button';
import { InputRef } from 'components/atoms/Input';
import TimerComponent from 'components/molecules/Timer';

const Style = {
  timerWrap: styled(Div)`
    top: 22px;
    right: 10px;
    position: absolute;
    color: #dd4124;
    font-size: 1.2rem;
  `,
  SubmitPinButton: styled(Button)`
    &.active {
      background-color: #000;
      color: #fff;
    }
    &.inactive {
      border: 1px solid #e1e3e2;
      color: #b1b3b2;
      background-color: #fff;
    }
  `,
};
// 1 전화번호가 db에 등록 되어있다
// 2 비밀번호 입력하고 로그인
// ui flow
// 전화번호 입력 -> 있음? -> 비밀번호 컴포넌트로 전화번호랑 같이 넘김

// 1 전화번호가 db에 등록 되어있지 않다
// 2 전화번호 인증
// 3 비밀번호 입력하고 회원가입
// ui flow
// 전화번호 입력 -> 없음 -> 번호 인증 -> 비밀번호 컴포넌트로 전화번호랑 같이 넘김
let pinId = null;

const Index = ({ callback, userPhone, skipCheckPhone }) => {
  let phone = null;
  const pinNumber = null;
  const [isCertify, setIsCertify] = useState(false);
  const [resetCnt, setResetCnt] = useState(0);
  const [pin, setPin] = useState('');
  const [phonenum, setPhonenum] = useState(userPhone);
  const [isValidate, setIsValidate] = useState(false);
  const [isPinTimeOut, setIsPinTimeOut] = useState(false);
  const [isPinError, setIsPinError] = useState(false);
  const [phoneCheck] = useMutation(USER_QUERY.IS_USER_PHONE);
  const [getPin] = useMutation(USER_QUERY.GET_CERTIFY_PIN);
  const [pinCheck] = useMutation(USER_QUERY.IS_CERTIFY_PHONE);

  useEffect(() => {
    setPhonenum(userPhone);
  }, [userPhone]);

  // api
  const handleSubmit = async () => {
    if (skipCheckPhone) {
      console.info('request pin number');
      requestPinNumber(pn);
      return;
    }
    console.info('try isExistingUser by phone');
    const pn = phone.value;
    // login({
    await phoneCheck({
      variables: {
        phonenum: pn,
      },
    })
      .then((res) => {
        const data = res.data.checkPhonenum;
        if (data.ok) {
          return requestPinNumber(pn);
          // user
          // 비밀번호 입력 페이지로 이동
        } else {
          // non user 핸드폰 인증
          alert('중복된 번호입니다');
        }

        // if(ok) {
        //   console.log()
        // }
      })
      .catch((err) => {
        switch (err.message) {
          case 'DUPLICATED_PHONENUM':
            alert(
              '이미 소셜채널 가입된 휴대폰 번호입니다.\n' +
                '본인 연락처가 맞다면 해당 계정으로\n' +
                '로그인해주세요.'
            );
            break;
          case 'INVALID_PHONENUM':
            alert('잘못된 전화번호 입니다.\n입력하신 번호를 확인해 주세요.');
            break;
        }
      });
  };

  const requestPinNumber = async (pn) => {
    // init setting
    setPin('');
    setResetCnt(resetCnt + 1);
    isPinTimeOut && setIsPinTimeOut(false);

    pinId = await getPin({
      variables: {
        phonenum: pn || phone.value,
      },
    }).then((res) => {
      const data = res.data.sendPhonePin;
      if (data.ok) {
        setIsCertify(true);
        return data.pinId;
      } else {
        // pin error
      }
    });
  };

  const handlePinNumber = async (a) => {
    const pn = phone.value;
    await pinCheck({
      variables: {
        pin,
        pinId,
      },
    })
      .then((res) => {
        const data = res.data.confirmPhonePin;
        setIsCertify(false);
        callback(data, pn);
        // if (data.ok){
        //   // user
        //   // 비밀번호 입력 페이지로 이동
        //   console.log('pin 인증 ok')
        //   // callback(pn, false); // false- > newbie
        // } else {
        //   // non user 핸드폰 인증
        //
        // }

        // if(ok) {
        //   console.log()
        // }
      })
      .catch((err) => {
        switch (err.message) {
          case 'INVALID_PIN_ID':
            alert('잘못된 요청입니다.\n 다시 시도해 주세요.');
            break;
          case 'INVALID_PIN':
            alert('인증번호가 일치하지 않습니다');
            break;
          case 'PHONENUM_CERTIFICATION_TIMEOUT':
            alert('제한시간이 초과되었습니다.\n다시 시도해 주세요');
            break;
          default:
            alert(
              '오류가 발생하였습니다.\n' +
                '다시 인증 요청해주세요.\n' +
                '반복적으로 오류가 발생하는 경우 고객센터로 문의주시기 바랍니다.'
            );
            break;
        }
      });
  };

  return (
    <div>
      <Div d-flex>
        <Div flex-grow>
          <InputRef
            value={phonenum}
            placeholder="연락처를 입력해주세요"
            border-round
            onChange={(e) => {
              setPhonenum(e.target.value);
              if (e.target.value.length > 10) {
                setIsValidate(true);
              } else {
                setIsValidate(false);
              }
            }}
            inputRef={(el) => (phone = el)}
          />
        </Div>
        <div style={{ width: '100px', marginLeft: '4px' }}>
          <OutlineButton
            type="button"
            className={isValidate ? 'active' : ''}
            disabled={!isValidate}
            onClick={handleSubmit}
          >
            {isCertify ? '재전송' : '확인'}
          </OutlineButton>
        </div>
      </Div>

      {isCertify && (
        <Div style={{ marginTop: '10px' }} d-flex>
          <Div flex-grow position-relative>
            <Style.timerWrap>
              <TimerComponent
                timerReset={resetCnt} // reset cnt를 변경해서..
                onTimeOut={() => {
                  console.log('time out');
                  setIsPinTimeOut(true);
                }}
              />
            </Style.timerWrap>

            <InputRef
              border-round
              onChange={(e) => {
                setPin(e.target.value);
              }}
            />
          </Div>
          <div style={{ width: '100px', marginLeft: '5px' }}>
            <Style.SubmitPinButton
              type="button"
              className={pin && pin.length === 4 ? 'active' : 'inactive'}
              disabled={!(pin && pin.length === 4) || isPinTimeOut}
              onClick={handlePinNumber}
              // onClick={requestPinNumber}
              fill={pin && pin.length === 4 && !isPinTimeOut}
            >
              확인
            </Style.SubmitPinButton>
          </div>
        </Div>
      )}
    </div>
  );
};

export default Index;
