import React from 'react';
import Head from 'next/head';
import styled from 'styled-components';

import { HOT_KEYWORDS } from 'utils/createEventMetaTag';

const Container = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.4rem;
  /* overflow-x: hidden; */
  position: relative;
  &.no-padding {
    padding: 0;
  }
  height: 100vh;
`;

export default function SiteLayout({ children, metaData, className }) {
  return (
    <Container className={className}>
      <Head>
        <title>언에이징: 젊어지는 비밀을 풀다</title>
        <meta name="robots" content="index,follow" />
        <meta property="og:image" content="/assets/images/share_odoctor.jpg" key="ogimage" />
        <meta property="og:url" content="unaging.co.kr" key="ogurl" />
        <meta property="og:site_name" content="언에이징: 젊어지는 비밀을 풀다" key="ogsitename" />
        <meta property="og:title" content="언에이징: 젊어지는 비밀을 풀다" key="ogtitle" />
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          property="og:description"
          content="오늘보다 내일 더 젊어보이는 이유, 젊어지는 비밀을 풀다"
          key="ogdesc"
        />
        <meta name="writer" content="www.unaging.co.kr" />
        <meta name="copyright" content="2024 Unaging" />
        <meta name="content-language" content="kr" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link rel="icon" href="/favicon.ico" />
        {metaData && ( // todo : metaDate.og 추가
          <>
            {metaData.title && <title>{metaData.title}</title>}
            {metaData.title && <meta name="title" content={metaData.title} />}
            {metaData.og_description && (
              <meta property="og:description" content={metaData.og_description} key="ogdesc" />
            )}
            {metaData.hospital && (
              <meta
                name="keywords"
                content={
                  metaData.keyword ||
                  `${metaData.hospital.name}, ${metaData.therapyName}, ${
                    metaData.keyEventName
                  },${HOT_KEYWORDS.join(',')},오닥터`
                }
              />
            )}
            {metaData.description && <meta name="description" content={metaData.description} />}
            {metaData.image && <meta property="og:image" content={metaData.image} key="ogimage" />}
            {metaData.title && (
              <meta
                property="og:site_name"
                content={`언에이징 | ${metaData.title}`}
                key="ogsitename"
              />
            )}
            {metaData.title && (
              <meta
                property="og:title"
                content={
                  metaData.hospital
                    ? `${metaData.hospital.name || ''} | ${metaData.title}`
                    : metaData.title
                }
                key="ogtitle"
              />
            )}
            {metaData.description && (
              <meta property="og:description" content={metaData.description} key="ogdesc" />
            )}
          </>
        )}
        <meta httpEquiv="Content-Type" content="text/html; charset=euc-kr" />
        <meta httpEquiv="Content-Script-Type" content="text/javascript" />
        <meta httpEquiv="Content-Style-Type" content="text/css" />
      </Head>
      <>{children}</>
    </Container>
  );
}
