import React from 'react';
import styled from 'styled-components';

export default function PhilingPickHeader({ onClickBtnBack, label }) {
  return (
    <Container>
      <Wrap onClick={onClickBtnBack}>
        <IconBack src="/assets/images/icons/icon_arrow.png" alt="뒤로가기" />
      </Wrap>
      <Label>{label}</Label>
    </Container>
  );
}

const Container = styled.header`
  position: relative;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -1.4rem;
`;

const Wrap = styled.div`
  position: absolute;
  left: 0;
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconBack = styled.img`
  width: 2.5rem;
  transform: rotate(180deg);
`;

const Label = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
`;
