import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 20rem;
`;

export const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: -0.75px;
`;

export const Apply = styled.div`
  margin-top: 1rem;
  margin-bottom: 6rem;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: -0.29px;
`;

export const ManagerRow = styled.div`
  display: flex;
  margin-top: 6rem;
  align-items: center;
`;

export const ManagerImage = styled.div`
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
`;

export const ManagerName = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.11;
`;

export const PlanDescription = styled.div`
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.29px;
`;

export const PlanInfoBannerWrapper = styled.div`
  margin-top: 3rem;
  padding: 2rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #000;
  color: #fff;
  z-index: 5;
  position: relative;
`;

export const Hmm = styled.div`
  position: absolute;
  bottom: 0;
  width: 100vw;
  max-width: 420px;
  margin: 0 -2rem;
  z-index: 3;
`;

export const BannerTitle = styled.div`
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.44;
  letter-spacing: -0.37px;
`;

export const BannerSummary = styled.div`
  margin-top: 0.5rem;
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: -0.4px;
`;

export const BannerRowWrapper = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const BannerDiscountRate = styled.div`
  color: #dd4124;
  font-size: 5rem;
  font-weight: 600;
  line-height: 5rem;
`;

export const BannerDate = styled.div`
  font-family: Saira;
  font-size: 1.4rem;
  text-align: right;
  color: #ffffff;
`;

export const ContentRow = styled.div`
  margin-top: 9rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.11;
  border-bottom: 2px solid #000;
  margin-bottom: 3rem;
  padding-bottom: 0.2rem;
`;

export const Advantage = styled.div`
  font-size: 1.4rem;
  margin-bottom: 2rem;
`;

export const AdvantageTitle = styled.div`
  color: #dd4124;
  font-weight: bold;
  letter-spacing: -0.29px;
  margin-bottom: 0.5rem;
`;

export const AdvantageSummary = styled.div`
  line-height: 1.43;
  letter-spacing: -0.4px;
`;

export const AdvantageWrapper = styled.div`
  margin-bottom: ${({ hasImage }) => (hasImage ? '4rem' : '30rem')};
`;

export const Bottom = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  margin: 0 -1.4rem;
`;
