/**
 *
 * @param number
 * @param width
 * @returns {string}
 * @description 익스플로러에서 String.padStart() 이 지원 안함
 */
export const numberPadding = (number, width = 2) => {
  const n = `${number}`;
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberFormat(value) {
  // value => 1234 or '1234'
  // return => '1,234'
  return new Intl.NumberFormat('ko-KR').format(numberBuffer(value * 1));
}

/* 핸드폰 번호형식 */
export function phoneNumberFormat(number) {
  return number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}

/* 전화번호 형식 02, 031, 01x */
export function telNumberFormat(number) {
  return number.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
}

export function onlyNumberFormat(value) {
  return value.replace(/[^0-9]/g, '');
}
export function numberPercentage(num, total, mode = 'floor') {
  return Math[mode]((num / total) * 100);
}

function numberBuffer(num) {
  if (num < 100) {
    return Math.round(num * 1.5) + 10; // 159
  }
  if (num < 200) {
    return Math.round(num * 1.2) + 20;
  }

  return num;
}
