import React, { useState, useEffect } from 'react';
import * as Styled from './index.styles';
import { useQuery } from '@apollo/client';
import USER_QUERY from 'graphql/user';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

const refText = {
  use: '사용',
  reward: '적립',
};

const NoHistory = () => <Styled.NoHistory>내역 없음</Styled.NoHistory>;

const Index = () => {
  const [history, setHistory] = useState([]);
  const { data, loading, error } = useQuery(USER_QUERY.GET_POINT_HISTORY);

  useEffect(() => {
    if (loading) return;
    setHistory(data.userPointHistory.data);
  }, [data, loading]);

  return (
    <div>
      <Styled.TitleDescribe bold>최근1개월</Styled.TitleDescribe>
      <Styled.Table>
        <div className="table_row table_row--header table_row--hidden">
          {/* <div className="wrapper">
            <div className="col col-rank">순위</div>
            <div className="col col-name">이름</div>
            <div className="col col-count">초대친구</div>
            <div className="col col-rewards">리워드</div>
          </div> */}
        </div>

        {history.length === 0 && <NoHistory />}
        {history.map((h, idx) => (
          <div className="table_row table_row--my" key={idx}>
            <div className="wrapper">
              <div className="col col-date">{dayjs(h?.createdAt).format('YYYY.MM.DD')}</div>
              <div className="col col-title">
                {h[`${h.eventType}Item`]?.itemName || '기프티콘 교환'}
              </div>
              <div className={`col col-usage ${h.eventType}`}>
                {h.pointAmount}P {refText[h.eventType]}
              </div>
            </div>
          </div>
        ))}
        {/* {history.map((h, idx) => (
          <div key={idx}>
            {h.createdAt} {h.id} {refText2[h.eventType]} {Math.floor(Math.abs(h.pointAmount) / 100)} {refText[h.eventType]}
          </div>
        ))} */}
      </Styled.Table>
    </div>
  );
};

export default Index;
