import React from 'react';
import styled, { css } from 'styled-components';

const cssRatioBg = css`
  background-image: url(${(props) => props.bg});
  background-size: cover;
`;
const RatioBoxWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

const RatioBoxInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  ${(props) => props.bg && cssRatioBg}
  &>div, &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RatioBox = ({ children, bg, ...others }) => {
  return (
    <RatioBoxWrapper {...others}>
      <RatioBoxInner bg={bg}>{children}</RatioBoxInner>
    </RatioBoxWrapper>
  );
};
