export const HOT_KEYWORDS = [
  '리프팅',
  '민트실리프팅',
  '코프팅',
  '스마스미니거상',
  '지방추출주사',
  '지팻주사',
  'MPL핏주사',
  '슈퍼시크릿주사',
  '식스팩주사',
  '제로네이트',
  '임플란트',
];

export default function createEventMetaTag(event) {
  try {
    const { name, description, therapyName, hospital, metaTag, thumbnailUrl } = event;
    if (metaTag) {
      return {
        title: metaTag.title.split('  ').join(' '),
        hospital,
        therapyName,
        keyEventName: name.split('$n').join(' ') || '',
        description: metaTag.description,
      };
    }

    return {
      title: `${therapyName} | 언에이징, 나를 위한 셀프케어 플랫폼`,
      hospital,
      therapyName,
      keyEventName: name.split('$n').join(' ') || '',
      description: description?.split('\n').join(' ') || '',
      image: thumbnailUrl,
    };
  } catch (error) {
    console.error(error);
    return {
      title: '',
      hospital: { name: '' },
      therapyName: '',
      keyEventName: '',
      description: '',
    };
  }
}
