export const webShare = async ({
  shareMsg = '오닥터 퀴즈를 풀고, 기프티콘을 받아가세요.',
  shareUrl = 'https://o-doctor.co.kr/',
  referralCode,
}) => {
  const url = `${shareUrl}${referralCode ? '?referral=' + referralCode : ''}`;
  if (window.navigator.share === undefined) {
    const input = document.createElement('input');
    input.id = 'copyItem';
    input.value = url;
    document.body.appendChild(input);
    const copyText = document.getElementById('copyItem');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.remove();
    alert('공유 URL이 복사 되었습니다');
    return;
  }

  try {
    await window.navigator.share({
      title: shareMsg,
      url: url,
    });
  } catch (error) {
    console.log(error);
  }
};
