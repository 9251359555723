import React, { useEffect } from 'react';
import * as Styled from './index.style';

const INTRO_MESSAGE = '정확한 진료와 처방을 위해\n몇 가지 질문을 드릴게요!';
const INTRO_SUBSCRIPTION = '답변해주신 내용은\n의사, 약사 선생님에게만 잘 전달해드릴게요.';

const IntroQuestion = ({ autoSkip }) => {
  useEffect(() => {
    autoSkip(3000);
  }, []);

  return (
    <Styled.Section d-flex flex-column align-items-center>
      <Styled.Title style={{ textAlign: 'center' }}>{INTRO_MESSAGE}</Styled.Title>
      <Styled.BgImageContainer />
      <Styled.Describe style={{ textAlign: 'center' }}>{INTRO_SUBSCRIPTION}</Styled.Describe>
    </Styled.Section>
  );
};

export default IntroQuestion;
