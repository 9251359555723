import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import * as Styled from './index.style';
import { useUser } from 'hooks/useUser';
import { usePinMutation, usePinCheckMutation } from 'hooks/phoneVerification/usePinMutation';
import { useCheckDuplicate } from 'hooks/phoneVerification/useCheckDuplicate';
import { PaperInput, PaperPinInput } from 'components';
import { confirm } from 'components/molecules/Modal';
import { isPhoneNumeric, isNumeric } from 'utils/validate';
import { deleteCookie } from 'utils/cookie';
import { convertPhoneNumberFormat } from 'utils/common';
import { resetAuth } from 'modules/auth/auth.actions';

const TIMEOUT_MESSAGE = '인증번호가 만료되었습니다.\n인증번호를 재전송하여 다시 받아주세요.';
const ERROR_MESSAGE = '인증번호가 일치하지 않습니다.';

const PhilingPhoneVerify = ({ data, requestPin, onChange }) => {
  const [disable, setDisable] = useState(true);
  const [phonenum, setPhonenum] = useState();

  const onClickHandler = () => {
    requestPin(phonenum);
  };

  useEffect(() => {
    const isPhone = isPhoneNumeric(phonenum);
    setDisable(!isPhone);
    if (isPhone) {
      onChange({
        phone: phonenum,
      });
    }
  }, [phonenum]);

  useEffect(() => {
    setPhonenum(data.phone);
  }, [data]);

  return (
    <div>
      <div style={{ paddingBottom: '14rem' }}>
        <Styled.Section>
          <PaperInput
            label="휴대폰 번호"
            placeholder="숫자만 입력해주세요."
            type="text"
            inputMode="numeric"
            maxLength="11"
            pattern="[0-9]*"
            initValue={data.phone}
            errorMsg="휴대폰 번호를 올바르게 입력해주세요."
            validate={(inputValue) => {
              return isPhoneNumeric(inputValue);
            }}
            onChange={(inputValue) => {
              if (isPhoneNumeric(inputValue)) {
                setPhonenum(inputValue);
                return;
              }
              setPhonenum(false);
            }}
          />
        </Styled.Section>
      </div>

      <Styled.FixedBottom>
        <Styled.NextButton
          type={'button'}
          className={`${disable ? '' : 'active'}`}
          onClick={onClickHandler}
          disabled={disable}
        >
          인증번호 전송 {!!disable}
        </Styled.NextButton>
      </Styled.FixedBottom>
    </div>
  );
};

const PhilingPhonePinVerify = ({ saveAnswer, data, requestPin }) => {
  const [disable, setDisable] = useState(true);
  const [pin, setPin] = useState();
  const [resetCnt, setResetCnt] = useState(0);
  const [isTimeout, setTimeout] = useState(false);
  const [isError, setError] = useState(false);

  const [checkPinNumber] = usePinCheckMutation({
    onError: (err) => {
      console.log(err.message);
      setError(true);
    },
    onCompleted: () => {
      saveAnswer({
        response: data.phone,
        info: { phonenum: convertPhoneNumberFormat(data.phone) },
      });
    },
  });

  const onClickHandler = () => {
    checkPinNumber({
      pin,
      pinId: data.pinId, // from Store
    });
  };

  useEffect(() => {
    setDisable(!isNumeric(pin, 4));
  }, [pin]);

  return (
    <div
      style={{
        marginTop: '-9rem',
        backgroundColor: '#eee',
      }}
    >
      <div className="title-box">
        <Styled.Title>
          {data?.phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')} 로
          전송된 인증번호를 입력해주세요.
        </Styled.Title>
        {false && <Styled.Describe></Styled.Describe>}
      </div>
      <div style={{ paddingBottom: '14rem' }}>
        <Styled.Section>
          <PaperPinInput
            label="인증번호"
            type="text"
            maxLength="1"
            inputMode="numeric"
            pattern="[0-9]*"
            initValue={null}
            errorMsg="휴대폰 번호를 올바르게 입력해주세요."
            cnt={resetCnt}
            validate={(inputValue) => {
              return isNumeric(inputValue);
            }}
            onChange={(inputValue) => {
              setPin(inputValue);
            }}
            onTimeOut={useCallback(() => {
              setPin(null);
              setTimeout(true);
            })}
          />
        </Styled.Section>
        {isTimeout && <Styled.TimeoutMessage>{TIMEOUT_MESSAGE}</Styled.TimeoutMessage>}
        {isError && <Styled.TimeoutMessage>{ERROR_MESSAGE}</Styled.TimeoutMessage>}
      </div>

      <Styled.FixedBottom>
        <div>
          <Styled.SkipButton
            onClick={() => {
              setResetCnt(resetCnt + 1);
              requestPin(data.phone);
              setTimeout(false);
              setError(false);
            }}
          >
            재전송
          </Styled.SkipButton>
        </div>

        <Styled.NextButton
          type="button"
          className={`${disable ? '' : 'active'}`}
          onClick={onClickHandler}
          disabled={disable}
        >
          다음 {!!disable}
        </Styled.NextButton>
      </Styled.FixedBottom>
    </div>
  );
};

const PhoneConfirm = ({ saveAnswer }) => {
  const [process, setProcess] = useState(0);
  const [data, setData] = useState({
    phone: null,
    pinId: null,
  });
  const router = useRouter();
  const { user } = useUser();
  const dispatch = useDispatch();

  const { PAPER_FORM } = useSelector((state) => state.form);

  useEffect(() => {
    setData({
      phone: PAPER_FORM.patientInfo.phonenum || user.phonenum,
    });
  }, [user]);

  const errorHandler = async (err) => {
    let op = {};
    const { query } = router;

    switch (err.message) {
      case 'DUPLICATED_PHONENUM':
        op = {
          text: '이미 등록된 연락처입니다.',
          subText:
            '연락처는 하나의 계정에만 등록할 수 있습니다.\n안전한 진료를 위해 계정을 확인하거나\n수정하여 다시 로그인해주세요',
          button: {
            cancel: {
              text: '전화번호 수정하기',
            },
            ok: {
              text: '다시 로그인하기',
            },
          },
        };
        break;
      case 'INVALID_PHONENUM':
        op = {
          text: '잘못된 전화번호 입니다.',
          subText: '입력하신 번호를 확인해 주세요.',
          button: {
            cancel: {
              text: '확인',
            },
          },
        };

        break;
    }

    if (await confirm(op)) {
      dispatch(resetAuth());
      deleteCookie({ name: 'token' }, () => {
        router.replace(`/philing/${query.symptomId}`);
      });
    }
  };

  const [requestPinNumber] = usePinMutation({
    onCompleted: (pinId) => {
      setProcess(1);
      setData({
        ...data,
        pinId,
      });
    },
  });

  const [checkPhoneDuplicate] = useCheckDuplicate({
    onError: (err) => {
      errorHandler(err);
    },
    onCompleted: () => {
      requestPinNumber(data.phone);
    },
  });

  const ui = [PhilingPhoneVerify, PhilingPhonePinVerify];
  const Component = ui[process];
  return (
    <Component
      data={data}
      saveAnswer={saveAnswer}
      requestPin={checkPhoneDuplicate}
      onChange={setData}
    />
  );
};

export default PhoneConfirm;
