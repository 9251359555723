export const isNumeric = (value, length) => {
  const r = new RegExp(`^[0-9]${length ? `{${length}}` : '+'}$`);
  return r.test(value);
};

export const isPhoneNumeric = (value) =>
  /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(value);

// 2 ~ 4
export const isName = (value) => /^[가-힣]{2,5}$|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/.test(value);
// 2 ~ 5
export const isSingleName = (value) => /^[가-힣]{2,5}$|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/.test(value);
// 19840101
export const isBirth = (value) =>
  /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/.test(value);
// 1900~2099
export const isBirthYear = (value) => /^(19[5-9][0-9]|20\d{2})$/.test(value);

export const isBetween = (number, min, max) => number >= min && number <= max;

export const isRRN = (value) =>
  /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]\d{6}/.test(value);
