import React, { useEffect, useState } from 'react';
import * as Styled from './index.styles';
import axios from 'axios';
import { imageCompress } from 'utils/compressor';
import useSignUrl from 'hooks/useSignS3';
import { RatioBox } from 'components/atoms/Image';

/**
 *
 * 1. request signed URL
 * 2. compress image size
 * 3. upload s3 by signed URL
 *
 * @param {*} param0
 * @returns
 *
 */
export const PhotoUploadBox = ({ onRemove, onUploadSuccess, ...props }) => {
  const [uploading, setUploading] = useState(true);

  const handleDelete = () => {
    onRemove();
  };
  const handleUpload = () => {
    onUploadSuccess();
    setUploading(false);
  };

  return (
    <RatioBox>
      {!uploading && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            zIndex: 9999,
          }}
          onClick={handleDelete}
        ></div>
      )}
      <MemorizedPreviewPhoto {...props} onUploadSuccess={handleUpload} uploading={uploading}>
        {!uploading && <Styled.PhotoDeleteButton className="svg-button-delete-photo" />}
      </MemorizedPreviewPhoto>
    </RatioBox>
  );
};

const PreviewPhoto = ({ children, path = 'testfolder', file, onUploadSuccess, onError }) => {
  const [imagePreview, setImagePreview] = useState();
  const [progress, setProgress] = useState(100); // pos top
  const [signUrl, url] = useSignUrl(path, file, (url) => {
    imageCompress({
      file,
      onSuccess: (compressedImage) => {
        axios
          .put(url, compressedImage, {
            onUploadProgress: (progressEvent) => {
              const progress = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );

              setProgress(200 * (100 / progress));
            },
            headers: {
              'Content-Type': file.type,
            },
          })
          .then(() => {
            console.log('Upload success');
            onUploadSuccess();
          })
          .catch((e) => {
            onError(e);
            console.log(e);
          });
      },
    });
  });

  useEffect(() => {
    signUrl();
    const reader = new FileReader();
    reader.onload = function (e) {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  return (
    <>
      {imagePreview && (
        <>
          <Styled.PhotoPreview className="wave-box" bg={imagePreview}>
            {children || (
              <>
                <div className="wave" style={{ top: `-${progress}%` }}></div>
                <div className="wave -two" style={{ top: `-${progress}%` }}></div>
                <div className="wave -three" style={{ top: `-${progress}%` }}></div>
              </>
            )}
          </Styled.PhotoPreview>
        </>
      )}
    </>
  );
};

export const MemorizedPreviewPhoto = React.memo(
  PreviewPhoto,
  function moviePropsAreEqual(prev, next) {
    return prev.file.name === next.file.name && prev.uploading === next.uploading;
  }
);
