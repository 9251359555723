import React from 'react';
import { useRouter } from 'next/router';

import * as Styled from './index.styles';

export default function PrevHeader({ mode = 1, children, onClick, onClose }) {
  const router = useRouter();

  return (
    <Styled.Container>
      <Styled.Wrapper>
        {!!(mode & 1) && (
          <Styled.HeaderButton
            onClick={() => (onClick ? onClick() : router.back())}
            className="svg-button-back"
            alt="뒤로가기"
          />
        )}
      </Styled.Wrapper>

      <Styled.Wrapper>{!!(mode & 2) && <Styled.HeaderButton logo />}</Styled.Wrapper>

      <Styled.Wrapper>
        {!!(mode & 4) &&
          React.cloneElement(
            <Styled.HeaderButton
              onClick={() => (onClose ? onClose() : router.back())}
              className="svg-button-close-cross"
              alt="닫기"
            />,
            {
              ...(children && children.props),
            },
            children
          )}
      </Styled.Wrapper>
    </Styled.Container>
  );
}
