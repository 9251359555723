import React, { useEffect } from 'react';
import * as Styled from './index.styles';
import { convertBoldLine } from 'utils/common';
import { text } from './terms.text';
import { CloseEdgeButton } from 'components/atoms/Button';

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.Container>
      <Styled.TermsHeaderWrap>
        <Styled.TermsHeader d-flex align-items-center justify-content-between>
          <div />
          <CloseEdgeButton terminate />
        </Styled.TermsHeader>
      </Styled.TermsHeaderWrap>
      <Styled.TermsTitleWrap>
        <Styled.TermsTitle>이용약관</Styled.TermsTitle>
        {/* <Styled.TermsDescription>
            오닥터는 의료법을 준수합니다 :D
          </Styled.TermsDescription> */}
      </Styled.TermsTitleWrap>
      <Styled.TermsWrap>
        {convertBoldLine(text, (line, idx) =>
          idx % 2 ? (
            <React.Fragment key={idx}>
              <b>{line}</b>
            </React.Fragment>
          ) : (
            line
          )
        )}
      </Styled.TermsWrap>
    </Styled.Container>
  );
};

export default Index;
