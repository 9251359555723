import styled from 'styled-components';

export const BoxWrapper = styled.div`
  display: flex;
  height: 58px;
  align-items: center;
`;

export const Box = styled.div`
  margin-right: 1.2rem;

  a {
    letter-spacing: -0.4px;
    font-size: 1.8rem;
    font-weight: bold;
    color: ${({ isActive }) => (isActive ? '#000' : '#b1b3b2')};
  }
`;

export const TextWrapper = styled.div`
  margin-top: 6rem;
`;

export const Title = styled.h1`
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 0.94;
  letter-spacing: -0.85px;
`;

export const Desc = styled.h3`
  font-size: 1.4rem;
  letter-spacing: -0.35px;
  margin-top: 1rem;
`;

export const CategoryWrapper = styled.div`
  position: relative;
  display: flex;
  background: #fff;
  margin-top: 3rem;
  margin-bottom: 6rem;
`;

export const ImgArrowDown = styled.img`
  position: absolute;
  right: 1.4rem;
  top: 2rem;
`;

export const SelectBox = styled.select`
  width: 100%;
  background-color: #fff;
  border: 1px solid #e1e3e2;
  border-radius: 4px;
  font-size: 1.3rem;
  line-height: 1.54;
  letter-spacing: -0.4px;
  padding: 1.8rem 1.4rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
`;

export const OfferBannerWrapper = styled.div`
  margin-top: 3rem;
  padding-bottom: 3rem;
  width: 100%;
  img {
    width: 100%;
  }
`;
