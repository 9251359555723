import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  border: 1px solid #e1e3e2;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e3e2;
  padding-bottom: 2rem;
`;

export const ImageWrapper = styled.div`
  width: 5.77rem;
  height: 5.77rem;
  margin-right: 1rem;
`;

export const InfoWrapper = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.37px;
`;

export const Address = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.29px;
`;

export const Dot = styled.span`
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #000;
  vertical-align: super;
  margin: 0 0.5rem;
`;

export const Body = styled.div`
  margin-top: 2rem;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceWrapper = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div``;

export const DiscountRate = styled.span`
  font-size: 1.8rem;
  line-height: 1.11;
  color: #dd4124;
  font-weight: 600;
`;

export const Price = styled.span`
  font-size: 1.8rem;
  color: #dd4326;
  font-weight: 600;
  margin-left: 0.5rem;
`;

export const Origin = styled.span`
  font-size: 1.4rem;
  color: #b1b3b2;
  line-height: 1.67;
  margin-left: 0.5rem;
  text-decoration: line-through;
`;

export const Desc = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-size: 1.4rem;
  letter-spacing: -0.29px;
`;

export const ButtonWrap = styled.div`
  display: flex;
`;

export const Button = styled.div`
  border: 1px solid #e1e3e2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.8rem 0;
  font-size: 1.3rem;
  letter-spacing: -0.6px;
  flex: 1;
  cursor: pointer;
`;

export const ButtonCart = styled(Button)`
  margin-left: 1rem;
  border-color: ${({ selected }) => selected && '#000'};
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;
