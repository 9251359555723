import { gql } from '@apollo/client';

export const GetEventReviews = gql`
  query GetEventReviews($eventId: ID!, $orderBy: String!, $pagination: PaginationInput) {
    eventReviews(eventId: $eventId, orderBy: $orderBy, pagination: $pagination) {
      data {
        id
        eventId
        nickname
        detail
        images {
          reviewId
          url
        }
        estimations {
          categoryId: reviewEstimationCategoryId
          rating
        }
        likeCount
        liked
        createdAt
      }
      meta {
        estimationSummary {
          categoryId: reviewEstimationCategoryId
          # topRatingRatio
          topRatingCount
          totalRatingCount
          name
        }
        estimationCategories {
          id
          name
          ratingEqualDivision
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const AddEventReview = gql`
  mutation AddEventReview($input: EventReviewInput!) {
    createEventReview(input: $input) {
      id
      eventId
      nickname
      detail
      images {
        id
        reviewId
        url
      }
      estimations {
        id
        reviewEstimationCategoryId
        rating
      }
      likeCount
      liked
    }
  }
`;

export const ToggleReviewLike = gql`
  mutation toggleEventReviewLike($reviewId: Int!) {
    toggleEventReviewLike(reviewId: $reviewId) {
      id
      liked
      likeCount
    }
  }
`;
