import React, { useState, useEffect } from 'react';
import * as Styled from './index.style';
import dayjs from 'dayjs';
import { rrnToBirthDate, getAge } from 'utils/date';
import { isName, isRRN, isBetween } from 'utils/validate';
import { useSelector } from 'react-redux';
import { useUser } from 'hooks/useUser';
import { PaperInput, PaperRRNInput, PaperCheckbox } from 'components';
import { OUTER_PAGE_LINK } from 'utils/constants';
import { useRouter } from 'next/router';
import useSymptom from 'hooks/philing/useSymptom';

const PersonalInformation = ({ saveAnswer, symptomId }) => {
  const { PRIVACY_POLICY, IMPORTANT_INFO } = OUTER_PAGE_LINK.PAPER_AGREEMENTS;

  const router = useRouter();
  const { user } = useUser();
  const { PAPER_FORM } = useSelector((state) => state.form);
  const { data } = useSymptom({ symptomId });

  const [disable, setDisable] = useState(true);
  const [initValue, setInitValue] = useState([null, null]);
  const [name, setName] = useState(null);
  const [rrn, setRRN] = useState(null);
  const [agree, setAgree] = useState(null);

  const errorHandler = () => {
    router.push(`/philing/${symptomId}/error`);
  };

  const validation = () => {
    const {
      medicalDepartment: { ageLimit, genderLimit },
    } = data;

    const userAge = getAge(rrnToBirthDate(rrn));

    if (!isBetween(userAge, ageLimit.min, ageLimit.max || 120)) {
      errorHandler();
      return false;
    }

    // 010 & 010
    if (!(genderLimit & [0, 1, 2, 1, 2, 1, 2][rrn.split('-')[1][0]])) {
      errorHandler();
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validation()) return;
    saveAnswer({
      response: `${name}`,
      info: {
        name: name,
        rrn: rrn,
        birthDate: rrnToBirthDate(rrn),
      },
    });
  };

  const onClickViewAgreements = (e) => {
    const page = [PRIVACY_POLICY, IMPORTANT_INFO][e.target.getAttribute('data-page')];
    if (page) {
      e.preventDefault();
      window.open(page, '_blank');
    }
  };

  useEffect(() => {
    if (isName(name) && isRRN(rrn)) {
      setDisable(false || !agree);
    } else {
      setDisable(true);
    }
  }, [name, rrn, agree]);

  useEffect(() => {
    setInitValue([user.birthDate ? dayjs(user.birthDate).format('YYMMDD') : null, null]);
    if (user) {
      setName(PAPER_FORM.patientInfo.name || user.name);
    }
  }, [user]);

  return (
    <Styled.Section>
      <div>
        <Styled.Section>
          <PaperInput
            label="이름"
            placeholder="이름을 입력해주세요."
            type="text"
            maxLength={4}
            initValue={PAPER_FORM.patientInfo.name || user?.name}
            errorMsg="이름을 올바르게 입력해주세요"
            validate={(inputValue) => {
              return isName(inputValue);
            }}
            onChange={(inputValue) => {
              if (isName(inputValue)) {
                setName(inputValue);
                return;
              }
              setName(false);
            }}
          />
        </Styled.Section>
        <Styled.Section>
          <PaperRRNInput
            label="주민등록번호"
            placeholder={['생년월일', '뒤 7자리']}
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={[6, 7]}
            initValue={initValue}
            disabled={[user.birthDate, false]}
            errorMsg="주민등록번호를 올바르게 입력해주세요."
            validate={(inputValue) => {
              return isRRN(inputValue);
            }}
            onChange={(inputValue) => {
              if (isRRN(inputValue)) {
                setRRN(inputValue);
                return;
              }
              setRRN(false);
            }}
          />
        </Styled.Section>
      </div>
      <div style={{ paddingBottom: '14rem' }}>
        <PaperCheckbox
          label={
            <Styled.ConfirmText onClick={onClickViewAgreements}>
              비대면 진료 및 처방을 위한 <span data-page={0}>개인정보 수집 · 이용</span>
              &nbsp;및&nbsp;
              <span data-page={1}>중요안내사항</span>에 동의합니다.
            </Styled.ConfirmText>
          }
          onChange={(inputValue) => {
            setAgree(inputValue);
          }}
        />
      </div>

      <Styled.FixedBottom>
        <Styled.NextButton
          onClick={handleSubmit}
          className={`${disable ? '' : 'active'}`}
          disabled={disable}
        >
          다음 {!!disable}
        </Styled.NextButton>
      </Styled.FixedBottom>
    </Styled.Section>
  );
};

export default PersonalInformation;
