export const SOCIAL = {
  kakao: '카카오톡',
  naver: '네이버',
  facebook: '페이스북',
};

export const SOCIAL_LOGIN_METHOD = [
  {
    provider: 'kakao',
    text: '카카오톡으로 로그인',
  },
  // {
  //   provider: 'facebook',
  //   text: '페이스북으로 로그인',
  // },
];

export const LOGIN_MESSAGE = [
  {
    failure: '오감퀴즈를 공유하고$n 포인트 쌓으려면 지금 로그인하세요!',
    success: '오감퀴즈 당첨자 등록을 위해서는$n로그인이 필요해요!',
    quiz: '',
    gLogin: '로그인해주세요',
  },
  {
    failure: '',
    quiz: '',
    success: '',
    gLogin: '리뷰는 의료법에 따라$n로그인 후 확인할 수 있어요!',
  },
  {
    failure: '',
    quiz: '',
    success: '',
    gLogin: '문진을 계속 진행하려면$n로그인이 필요해요!',
  },
];

export const GENDER_GROUP = [
  {
    label: '남',
    value: 1,
  },
  {
    label: '여',
    value: 2,
  },
];

export const AGE_GROUP = [
  { label: '10대 미만', value: 'underTen' },
  { label: '10대', value: 'teenager' },
  { label: '20대', value: 'twenties' },
  { label: '30대', value: 'thirties' },
  { label: '40대', value: 'forties' },
  { label: '50대', value: 'fifties' },
  { label: '60대 이상', value: 'overSixties' },
];

export const CONTACTABLE_TIME = [
  { label: '언제든지', value: 'ANYTIME' },
  { label: '오전', value: 'MORNING' },
  { label: '오후', value: 'AFTERNOON' },
];

export const DEFAULT_PAGING = 10;

// 한시간
export const REVALIDATE = 60 * 10 * 6;

export const VOTE_LIST_STATUS = {
  BEFORE_START: '',
  ONGOING: '이벤트 진행중',
  TERMINATED: '',
};

export const VIDEO_IN_EVENT = [
  {
    eventId: '13',
    platform: 's3',
    src: 'https://v2-odoctor-resource.s3.ap-northeast-2.amazonaws.com/event/13/tu.mp4',
    visibleTypes: ['c'],
    isSquare: false,
  },
  {
    eventId: '29',
    platform: 'vimeo',
    src: 'https://vimeo.com/537161595',
    visibleTypes: ['c'],
    ratio: 1,
    isSquare: true,
  },
  {
    eventId: '66',
    platform: 'vimeo',
    src: 'https://vimeo.com/558898636',
    visibleTypes: ['c'],
    isSquare: false,
  },
  {
    eventId: '67',
    platform: 'vimeo',
    src: 'https://vimeo.com/558898636',
    visibleTypes: ['c'],
    isSquare: false,
  },
  {
    eventId: '68',
    platform: 'vimeo',
    src: 'https://vimeo.com/537159875',
    visibleTypes: ['c'],
    isSquare: false,
  },
  {
    eventId: '96',
    platform: 'vimeo',
    src: 'https://vimeo.com/558898636',
    visibleTypes: ['c'],
    isSquare: false,
  },
  {
    eventId: '102',
    platform: 'vimeo',
    src: 'https://vimeo.com/558896979',
    visibleTypes: ['c'],
    isSquare: false,
  },
];

export const VOTE_WINNERS_TITLE = {
  REPLY_LIKE_RANK: '베스트 댓글',
  VOTE_WINNER_TEAM: '승리팀 추첨',
};

export const APP_URL = process.env.NEXT_PUBLIC_V2_URL || '';

export const PHILING_ERROR = {
  CHECK: {
    HAIR_LOSS: '탈모약은 만 18세 이상 성인에 한하여 처방 가능해요.',
    ERECTILE_DYSFUNCTION: '발기부전 치료제는 만 18세 이상의 성인 남성에 한하여 처방 가능해요.',
    BIRTH_CONTROL: '피임약은 만 18세 이상 성인 여성에 한하여 처방 가능해요.',
    DIET: '다이어트 치료제는 만 18세 이상 성인에 한하여 처방 가능해요.',
  },
};

export const TREATMENT_PROCESS = [
  '접수대기',
  '진료대기',
  '진료완료',
  '조제대기',
  '조제완료',
  '배송완료',
];

/**
 * value : status int
 * step : process step -> TREATMENT_PROCESS[step]
 */
export const TREATMENT_STATUS = {
  WAITING_HOSPITAL_RECEPTION: {
    value: 1,
    step: 0,
    name: '접수대기',
    title: '가장 빨리 진료가 가능한$n병원을 찾고 있어요',
    subTitle: ['접수된 병원을 원하지 않을 경우$n최대 2번까지 거절할 수 있어요'],
  },
  WAITING_MATCHING: {
    value: 3,
    step: 0,
    name: '매칭대기',
    title: '진료를 위해 병원에서$n10분 내로 전화드릴 예정이에요.',
    subTitle: ['접수된 병원을 원하지 않을 경우$n최대 2번까지 거절할 수 있어요'],
  },
  COMPLETE_HOSPITAL_RECEPTION: {
    value: 4,
    step: 1,
    name: '진료대기',
    title: '진료를 위해 병원에서$n10분 내로 전화드릴 예정이에요.',
    subTitle: [
      '접수된 병원과 신료를 원하지 않는다면$n접수 후 1분 안으로 거절해주세요.',
      '혹시 병원에서 전화가 오지 않거나$n문의사항이 있다면 병원으로 연락주세요.',
    ],
  },
  WAITING_TREATMENT: {
    value: 5,
    step: 1,
    name: '진료대기',
    title: '진료를 위해 병원에서$n10분 내로 전화드릴 예정이에요.',
    subTitle: [
      '접수된 병원과 신료를 원하지 않는다면$n접수 후 1분 안으로 거절해주세요.',
      '혹시 병원에서 전화가 오지 않거나$n문의사항이 있다면 병원으로 연락주세요.',
    ],
  },
  CANCELED_TREATMENT: {
    name: '접수취소',
    title: '병원의 요청으로$n접수가 취소되었어요.',
    subTitle: ['병원의 일방적인 접수취소로 불편하셨다면$n고객센터로 신고해주세요.'],
  },
  // 정상 종료
  COMPLETE_TREATMENT: {
    value: 8,
    name: '진료완료',
    step: 2,
    title: '처방전을 확인하고$n약 수령방법을 선택해주세요.',
    subTitle: ['택배를 이용하면 더욱 편리하게$n약을 받아보실 수 있어요.'],
  },
  COMPLETE_TREATMENT_1: {
    // 처방전 없음
    value: 8,
    name: '진료종료',
    title: '처방 없이$n진료가 완료되었어요.',
    subTitle: ['만약 처방전이 누락되었다면$n고객센터로 문의해주세요.'],
  },
  COMPLETE_TREATMENT_2: {
    // 처방전 기한 만료
    name: '진료종료',
    title: '처방전 유효기간이$n만료되었어요.',
    subTitle: ['다시 처방을 받으려면 새롭게 진료를 신청해주세요.'],
  },
  COMPLETE_TREATMENT_3: {
    // 약 직접 수령
    name: '진료종료',
    title: '처방전을 출력하여$n약국을 방문해주세요.',
    subTitle: [],
    link: {
      title: '약국 방문수령 방법 보기',
      url: '',
    },
  },
  WAITING_PHARMACY_RECEPTION: {
    name: '조제대기',
    title: '약국에서 처방전을 확인하고$n조제약을 등록할 예정이에요.',
    subTitle: ['약에 대한 요청사항이나 문의사항이 있다면$n약국으로 연락주세요.'],
  },
  COMPLETE_PHARMACY_RECEPTION: {
    // 확정 후
    name: '조제대기',
    title: '조제약과 약값을 확인하고$n약값을 결제해주세요.',
    subTitle: ['약에 대한 요청사항이나 문의사항이 있다면$n약국으로 연락주세요.'],
  },
  WAITING_PHARMACY_FILL: {
    // 결제후
    name: '조제대기',
    title: '약국에서 약을$n조제하고 있어요',
    subTitle: ['올바른 복약을 위해 약국에서$n복약안내 전화를 드릴 예정이에요.'],
  },
  COMPLETED_PHARMACY_FILL: {
    // 결제후
    name: '조제대기',
    title: '약국에서 약을$n조제하고 있어요',
    subTitle: ['올바른 복약을 위해 약국에서$n복약안내 전화를 드릴 예정이에요.'],
  },
  IN_DELIVERY: {
    // 결제후
    name: '조제완료',
    title: '조제된 약을 꼼꼼하게 포장하여$n발송했어요.',
    subTitle: ['만약 약을 배송 받지 못했다면$n고객센터로 문의해주세요.'],
  },
  COMPLETED_DELIVERY: {
    // 결제후
    name: '배송완료',
    title: '배송지로 약 배송이$n완료되었어요.',
    subTitle: ['받으신 진료나 약에 대한 문의사항이 있다면$n병원 혹은 약국으로 연락주세요.'],
  },
};

export const questions = [
  {
    label: '필링은 어떤 서비스인가요?',
    link: 'https://alike-leech-719.notion.site/eff00b51fb8741b5a5be55202126b7eb#71ea886395a94605b05472d95b99e1c1',
  },
  {
    label: '이용 가능한 지역이 있나요?',
    link: 'https://alike-leech-719.notion.site/eff00b51fb8741b5a5be55202126b7eb#9b9770e707ff46eab57a33f2cf8e8132',
  },
  {
    label: '진료는 어떻게 받나요?',
    link: 'https://alike-leech-719.notion.site/eff00b51fb8741b5a5be55202126b7eb#68ca221d825145f3bdce09cbe54ce19c',
  },
  {
    label: '약은 어떻게 받을 수 있나요?',
    link: 'https://alike-leech-719.notion.site/eff00b51fb8741b5a5be55202126b7eb#7f3bc7af06ea402595bba618a4996b0d',
  },
  {
    label: '약 배송은 얼마나 걸리나요?',
    link: 'https://alike-leech-719.notion.site/eff00b51fb8741b5a5be55202126b7eb#6de0a7e67490494cba5781b055e91012',
  },
];

export const subQuestion = {
  1: [
    {
      label: '탈모의 원인은 무엇인가요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#922655db26ec4ffe8aeebf006a4169bd',
    },
    {
      label: '탈모엔 어떤 치료가 필요한가요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#d3b4340293c647c4a9e788c4fb47bd18',
    },
    {
      label: '탈모약엔 어떤 것이 있나요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#7aa02aab56eb488f995dcdcee904f8e4',
    },
    {
      label: '탈모약의 부작용은 없나요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#f2e81669d6094055a48396cafe9692a2',
    },
  ],
  2: [
    {
      label: '탈모의 원인은 무엇인가요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#922655db26ec4ffe8aeebf006a4169bd',
    },
    {
      label: '탈모엔 어떤 치료가 필요한가요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#d3b4340293c647c4a9e788c4fb47bd18',
    },
    {
      label: '탈모약엔 어떤 것이 있나요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#7aa02aab56eb488f995dcdcee904f8e4',
    },
    {
      label: '탈모약의 부작용은 없나요?',
      link: 'https://alike-leech-719.notion.site/533c528be506474a917c46f6cf90e05e#f2e81669d6094055a48396cafe9692a2',
    },
  ],
  3: [
    {
      label: '발기부전의 원인은 무엇인가요?',
      link: 'https://alike-leech-719.notion.site/ca97af22d07d4525b9e47f088202201e#e71ca408e1a149ccbf5abc2459ba2879',
    },
    {
      label: '발기부전 약은 꼭 처방전이 필요한가요?',
      link: 'https://alike-leech-719.notion.site/ca97af22d07d4525b9e47f088202201e#af05295595c54e1d96f99df859f615a5',
    },
    {
      label: '어떤 발기부전 약이 제일 좋나요?',
      link: 'https://alike-leech-719.notion.site/ca97af22d07d4525b9e47f088202201e#5096f8690e2a45c8a2baa9a62169246a',
    },
    {
      label: '발기부전 약의 부작용은 무엇인가요?',
      link: 'https://alike-leech-719.notion.site/ca97af22d07d4525b9e47f088202201e#2e4c951cf4ab405aa05a61053d0396d5',
    },
  ],
  4: [
    {
      label: '경구 피임약엔 어떤 것이 있나요?',
      link: 'https://alike-leech-719.notion.site/ad8a2acba8044cc98b0a909a4c8c9395#d63ce33d6ec64b698a269af48aba76ef',
    },
    {
      label: '경구 피임약을 먹으면 좋은 점이 있나요?',
      link: 'https://alike-leech-719.notion.site/ad8a2acba8044cc98b0a909a4c8c9395#270dfa7d56ba40d28327c950d7d5d26b',
    },
    {
      label: '경구 피임약의 부작용은 무엇인가요?',
      link: 'https://alike-leech-719.notion.site/ad8a2acba8044cc98b0a909a4c8c9395#e0ef52d6c0c543d29ef049aaf727f22c',
    },
  ],
  5: [
    {
      label: '다이어트 약은 누구가 복용해도 되나요?',
      link: 'https://alike-leech-719.notion.site/9a91e08f32f14519b8f00a42614ec7a2#0e03041355884ef881c97339fe4db0ef',
    },
    {
      label: '비만 치료제엔 어떤 것이 있나요?',
      link: 'https://alike-leech-719.notion.site/9a91e08f32f14519b8f00a42614ec7a2#f687e722db1e474387056a68170cf324',
    },
    {
      label: '필링에서 모든 비만 치료제를 처방 받을 수 있나요?',
      link: 'https://alike-leech-719.notion.site/9a91e08f32f14519b8f00a42614ec7a2#7998fcc48f8f45199748da80d075cb55',
    },
    {
      label: '비만 치료제의 부작용은 무엇인가요?',
      link: 'https://alike-leech-719.notion.site/9a91e08f32f14519b8f00a42614ec7a2#85f63d75bdb14233bcc38363b631ff2d',
    },
  ],
};

export const OUTER_PAGE_LINK = {
  LOGIN: {
    TERMS_OF_USE: 'https://alike-leech-719.notion.site/97c71a602c5c43b481397f2e0d20ac62',
    PRIVACY_POLICY: 'https://alike-leech-719.notion.site/b4d2caff3e2543fc8468b6a607406011',
    PRIVACY_SENSITIVE_POLICY:
      'https://alike-leech-719.notion.site/95742dd98ff2437f8135bf7c095b74a2',
  },
  PAPER_AGREEMENTS: {
    PRIVACY_POLICY: 'https://alike-leech-719.notion.site/1ad692bf740240fda773ff466e30faf4',
    IMPORTANT_INFO: 'https://alike-leech-719.notion.site/7bd6dc6941734922be56b2dd0af79c50',
  },
};
