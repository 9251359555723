import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import usePoint from 'hooks/usePoint';
import { PrevHeader } from 'components';
import { useUser } from 'hooks/useUser';
import { webShare } from 'utils/webShare';
import * as Styled from './index.styles';
import GiftList from 'components/organisms/GiftList';
import PointHistory from 'components/organisms/PointHistory';
import SkeletonComponent, { PointSkeleton } from './skeleton';

const buttons = [
  {
    name: '친구초대',
    point: '90',
    callback: (user, router) => {
      webShare({
        referralCode: user.referralCode,
      });
    },
  },
  // {
  //   name: '퀴즈참여',
  //   point: '30',
  //   callback: (user, router) => {
  //     router.push('/quiz');
  //   },
  // },
  // {
  //   name: '치료후기',
  //   point: '50',
  //   callback: (user, router) => {
  //     window.open(`https://bryan237381.typeform.com/to/NqR6bVXy#userid=${user.id}`, '_blank');
  //   },
  // },
];

const Index = () => {
  const router = useRouter();
  const [fetchEvent, isFetching, data] = usePoint();
  const { user } = useUser();

  useEffect(() => {
    fetchEvent();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [fetchEvent]);

  return (
    <Styled.Container>
      <Styled.Header d-flex align-items-center>
        <PrevHeader mode={5}>
          <div
            className="notice"
            onClick={() => {
              // router.push('/my/notice');
              window.open('https://www.notion.so/086e4cb8d072436c902f2923f0750f2a', '_blank');
            }}
          >
            안내사항
          </div>
        </PrevHeader>
      </Styled.Header>
      <Styled.PointTitle>
        My Point : {isFetching ? <PointSkeleton /> : data?.myPoint?.pointAmount}
      </Styled.PointTitle>
      {isFetching ? (
        <SkeletonComponent />
      ) : (
        <div style={{ marginTop: '9rem' }}>
          <Tabs defaultIndex={sessionStorage.getItem('tbi') || 0}>
            <Styled.TabWrap>
              <TabList style={{ display: 'flex' }}>
                <Tab>
                  <Styled.Label>기프티콘 교환</Styled.Label>
                </Tab>
                <Tab>
                  <Styled.Label style={{ marginLeft: '12px' }}>포인트 내역</Styled.Label>
                </Tab>
              </TabList>
            </Styled.TabWrap>

            <Styled.TabPanelWrap>
              <TabPanel>
                <GiftList rewardItem={data.userPointUseItems} />
              </TabPanel>
              <TabPanel>
                <PointHistory />
              </TabPanel>
            </Styled.TabPanelWrap>
          </Tabs>
        </div>
      )}
    </Styled.Container>
  );
};

export default Index;
