import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { Button } from 'components/atoms/Button';

export const RatioBox = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

export const PhotoPreview = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
`;

export const PhotoDeleteButton = styled.div`
  width: 100%;
  height: 100%;
`;
