import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';
import { Button as Btn, OutlineButton } from 'components/atoms/Button';

export const Container = styled.div`
  position: relative;
  font-size: 2rem;
  padding-top: 11.8rem;
  padding-bottom: 12rem;
`;

export const Header = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
  color: #000;
  height: 5.8rem;
  .notice {
    cursor: pointer;
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const PointTitle = styled.div`
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 600;
`;

export const PointButtonWrap = styled(Div)`
  margin: 0 -5px;
`;

export const TabWrap = styled(Div)`
  .react-tabs__tab {
    color: #b1b3b2;
  }
  .react-tabs__tab--selected {
    color: #000;
  }
`;

export const TabPanelWrap = styled(Div)`
  margin-top: 3rem;
`;
export const Label = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
`;
export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const MyHeader = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
  color: #000;
  height: 5.8rem;
  .tab {
    padding: 0.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .on-going {
  }
  .my-info,
  .my-counsel {
    color: #8e8e8e;
  }
  .notice {
    margin-left: auto;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const UserName = styled(Div)`
  margin-right: 0.4rem;
  font-size: 1.4rem;
`;

export const UserPhone = styled(Div)`
  font-size: 1.2rem;
`;

export const Tabs = styled(Div)`
  margin: 0 -0.6rem;
`;

const BlackButton = css`
  background-color: #000;
  border: none;
  button {
    color: #fff;
    font-size: 1.3rem;
    font-weight: bold;
  }
`;
export const Button = styled(Btn)`
  border-color: #000;
  color: #000;
  ${(props) => props.bgBlack && BlackButton}
`;
export const PointButton = styled(OutlineButton)`
  margin: 0 5px;
`;

export const TitleBig = styled.h1`
  font-size: 4rem;
`;

export const Image = styled.div``;

export const Title = styled.div``;

export const Description = styled.div``;

export const HospitalName = styled.div``;

export const Count = styled.div``;
