export const getDDay = (target) => {
  const dday = new Date(target).getTime();
  const now = new Date().getTime();
  const distance = dday - now;

  return Math.floor(distance / (1000 * 60 * 60 * 24));
};

export const convertDateToTime = (date, offset = 1000) => Math.floor(new Date(date) / offset);

export const getAge = (birth) => {
  const today = new Date();
  const birthDate = new Date(birth.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')); // 2000년 8월 10일

  return today.getFullYear() - birthDate.getFullYear();
};

export const rrnToBirthDate = (rrn) => {
  const years = [null, '19', '19', '20', '20'];
  const rrnArray = rrn.split('-');
  const birth = rrnArray[0].match(/.{1,2}/g);

  return [`${years[rrnArray[1].charAt(0)]}${birth[0]}`, birth[1], birth[2]].join('-');
};
