import styled, { css } from 'styled-components';
import Div from 'components/atoms/div';

const borderColor = css`
  &.active {
    border-color: #e1ea68;
  }
  &.error {
    border-color: #db7979;
  }
  &.in-active {
    color: #aaaaaa;
  }
`;

export const Container = styled.div`
  background-color: #f3f1f0;
  position: relative;
  font-size: 2rem;
  height: 100vh;
`;

export const Content = styled.div`
  padding-top: 9.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  margin-top: -20rem;
  font-size: 2rem;
  line-height: 1.4;
  margin: -0.4rem 0;
  font-weight: bold;
  white-space: pre-wrap;
`;

export const Describe = styled.div`
  margin-top: 8rem;
  font-size: 1.4rem;
  line-height: 1.4;
  white-space: pre-wrap;
`;

export const BgImageContainer = styled.div`
  margin-top: 3.7rem;
  width: 24rem;
  height: 24rem;
  background-image: url('/assets/images/paper/paper-intro-m-doctor-001.png');
  background-size: contain;
`;

export const Section = styled(Div)`
  margin-bottom: 3.4rem;
`;

export const TimeoutMessage = styled(Div)`
  white-space: pre-wrap;
  word-break: keep-all;
  font-size: 1.2rem;
  color: #db7979;
  font-weight: bold;
  text-align: center;
`;

export const ConfirmText = styled(Div)`
  white-space: pre-wrap;
  word-break: keep-all;
`;

export const PaymentCardListWarp = styled.div``;

export const ConfirmInput = styled.div`
  border-radius: 2rem;
  background-color: #fff;
  padding: 2.8rem 2.5rem;
  font-size: 1.2rem;
  color: #aaa;
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 1.6rem;
  column-gap: 3.5rem;
  span:nth-child(2n) {
    color: #000;
    font-weight: bold;
  }
`;

export const CautionText = styled.div`
  margin-top: 7rem;
  color: #444;
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const NextButton = styled.button`
  font-size: 1.6rem;
  width: 100%;
  height: 6.4rem;
  background-color: #ccc;
  color: #aaa;
  border-radius: 2rem;
  &.active {
    background-color: #e1ea68;
    color: #000;
    font-weight: bold;
  }
`;

export const FixedBottom = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 0 -2rem;
  padding: 0 2rem;
  position: absolute;
  bottom: 4rem;
  text-align: center;
  z-index: 10;
`;

export const SkipButton = styled.button`
  color: #666;
  font-size: 1.2rem;
  height: 3rem;
  width: 8rem;
  border-radius: 1.5rem;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 2.6rem;
`;

export const Button = styled.button`
  font-size: 1.4rem;
  border-radius: 2rem;
  border: 2px solid #ccc;
  width: 100%;
  height: 5.8rem;
  background-color: #fff;

  ${borderColor}
`;

export const Label = styled.div`
  font-size: 1.2rem;
  line-height: 1;
  color: #444;
  margin-bottom: 1.5rem;
`;

export const SelectWrap = styled.div`
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 -0.6rem;
`;
