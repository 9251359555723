import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { GET_REWARD_ITEM } from 'graphql/user';

export default function usePoint() {
  const dispatch = useDispatch();

  const optionEvent = {
    onCompleted: (res) => {
      // dispatch(getEvent.success(res.event));
    },
  };

  const [fetchEvent, { called, loading, data }] = useLazyQuery(GET_REWARD_ITEM, optionEvent);

  const isFetching = !(!loading && called);

  return [fetchEvent, isFetching, data];
}
