import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Skeleton from 'react-loading-skeleton';

import * as Styled from './index.styles';
import { LazyImage, CardSelectable } from 'components';
import usePackage from 'hooks/usePackage';
import PhilingPickHeader from 'components/atoms/PhilingPickHeader';

export default function Package() {
  const router = useRouter();
  const { packageId } = router.query;

  const {
    name,
    applyCount,
    events,
    pickPoints,
    contentsImageUrl,
    onSelectEvent,
    loading,
  } = usePackage(packageId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.Container>
      <PhilingPickHeader onClickBtnBack={() => router.push('/pick')} />
      {loading && <Loading />}
      <div style={{ paddingTop: '3rem' }}>
        {name.split('$n').map((value, idx) => (
          <Styled.Title key={idx}>{value}</Styled.Title>
        ))}
      </div>
      <Styled.Apply>최근 일주일간 {applyCount}명 신청중</Styled.Apply>
      <div>
        {events.map((event, idx) => (
          <CardSelectable key={idx} handleSelect={onSelectEvent} packageId={packageId} {...event} />
        ))}
        <>
          {pickPoints.length !== 0 && <Styled.ContentRow>Pick Point</Styled.ContentRow>}
          <Styled.AdvantageWrapper hasImage={!!contentsImageUrl}>
            {pickPoints.map(({ title, description }, idx) => (
              <Styled.Advantage key={idx}>
                <Styled.AdvantageTitle>{`${idx + 1}. ${title}`}</Styled.AdvantageTitle>
                <Styled.AdvantageSummary>{description}</Styled.AdvantageSummary>
              </Styled.Advantage>
            ))}
          </Styled.AdvantageWrapper>
          {contentsImageUrl && (
            <div>
              <LazyImage
                src={contentsImageUrl}
                alt={name}
                style={{ maxWidth: '420px', width: '100%' }}
              />
            </div>
          )}
        </>
      </div>
    </Styled.Container>
  );
}

const Loading = () => (
  <div style={{ marginTop: '3rem' }}>
    <Skeleton count={2} height="3rem" />
    <Skeleton style={{ marginTop: '2rem' }} height="2rem" />
    <div style={{ marginTop: '3rem' }}>
      <Skeleton count={3} />
    </div>
    <div style={{ marginTop: '3rem' }}>
      <Skeleton height="15rem" />
    </div>
  </div>
);
