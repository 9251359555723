import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { GET_CERTIFY_PIN, IS_CERTIFY_PHONE } from 'graphql/user';

/**
 * 핸드폰 번호 인증 : 4자리 pin 요청
 * @param { phonenum } param0
 * @returns
 */
export const usePinMutation = ({ /*phonenum,*/ onCompleted }) => {
  const option = {
    // variables: {
    //   phonenum,
    // },
    onCompleted: (response) => {
      const data = response.sendPhonePin;
      if (data.ok) {
        // setIsCertify(true);
        onCompleted(data.pinId);
      }
    },
  };

  // }
  const [mutate, { loading, error }] = useMutation(GET_CERTIFY_PIN, option);

  const requestPinNumber = useCallback(
    (phonenum) => {
      mutate({
        variables: {
          phonenum,
        },
      });
    },
    [mutate]
  );

  return [requestPinNumber, loading, error];
};

/**
 * 핸드폰 번호 인증 : 4자리 pin 입력 확인
 * @param { pin } param0
 * @returns
 */
export const usePinCheckMutation = (/*{ pin, pinId }*/ { onCompleted, onError }) => {
  const option = {
    // variables: {
    //   pin,
    //   pinId,
    // },
    onError: (err) => {
      onError(err);
    },
    onCompleted: (response) => {
      const data = response.confirmPhonePin;
      if (data.ok) {
        onCompleted();
      }
    },
  };

  // }
  const [mutate, { loading, error }] = useMutation(IS_CERTIFY_PHONE, option);

  const checkPinNumber = useCallback(
    ({ pin, pinId }) => {
      mutate({
        variables: {
          pin,
          pinId,
        },
      });
    },
    [mutate]
  );

  return [checkPinNumber, loading, error];
};
