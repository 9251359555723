import styled from 'styled-components';
import Div from 'components/atoms/div';

export const Container = styled.div`
  position: relative;
  font-size: 2rem;
  padding-top: 11.8rem;
  padding-bottom: 12rem;
`;

export const TitleSmall = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 8px;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.2;
`;

export const TitleDescribe = styled(Div)`
  font-size: 1.4rem;
  color: #000;
  line-height: 1.4;
  margin-top: 6px;
`;

export const Header = styled(Div)`
  position: absolute;
  width: 100%;
  top: 0;
  color: #000;
  height: 5.8rem;
`;

export const Content = styled(Div)`
  width: 100%;
`;

export const NoHistory = styled(Div)`
  width: 100%;
  padding: 9rem 0;
  text-align: center;
  color: #b1b3b2;
  font-size: 1.4rem;
`;

export const Table = styled.div`
  margin-top: 1rem;
  .table_row {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: 2rem 0;
    border-bottom: 1px solid #e1e3e2;
    box-sizing: border-box;
    .wrapper {
      width: 100%;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }

    .col {
      flex: 2 1 25%;
      -webkit-box-flex: 0;
      vertical-align: top;
      font-size: 1.4rem;
      -webkit-font-smoothing: antialiased;
    }
    .col-date {
      flex-basis: 20%;
    }
    .col-title {
      text-align: center;
    }
    .col-usage {
      text-align: right;
      flex-basis: 20%;
      &.reward {
        color: #dd4124;
      }
    }
  }

  .table_row--header {
    border-bottom: 1px solid #000;
    .col {
      font-weight: bold;
    }
  }
  .table_row--my {
    .col {
    }
  }
  .table_row--hidden {
    padding: 0;
  }
`;
