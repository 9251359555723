// import crypto from 'crypto';

// const mpw = 'odoc_enkey';
// /**
//  *
//  * @param string
//  * @returns {string}
//  */
// export const encrypt = (string) => {
//   const key = crypto.createCipher('aes-128-cbc', mpw);
//   let str = key.update(string, 'utf8', 'hex');
//   str += key.final('hex');
//   return str;
// };

// /**
//  *
//  * @param encryptedString
//  * @returns {string}
//  */
// export const decrypt = (encryptedString) => {
//   try {
//     const key = crypto.createDecipher('aes-128-cbc', mpw);
//     let str = key.update(encryptedString, 'hex', 'utf8');
//     str += key.final('utf8');
//     return str;
//   } catch (error) {
//     return null;
//   }
// };

export const hashDate = (length = 36) => (+new Date()).toString(36);
