export const initialState = {
  isFetching: true,
  myPoint: null,
  userPointUseItem: null,
};

export const actionTypes = {
  FETCH_DATA: 'FETCH_DATA',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA:
      return { ...state, isFetching: true };
    case actionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...convertToModel(state, action),
      };
    default:
      return state;
  }
};

const convertToModel = (state, action) => {
  const { myPoint, userPointUseItem } = action.payload;

  return {
    myPoint,
    userPointUseItem,
  }
};
