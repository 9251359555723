import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { GetEventPackage } from '../graphql/event';
import { selectEvent } from '../modules/package/package.actions';
import { pixelEvent } from 'utils/pixel';

function setInitialModel(packageData) {
  if (packageData) {
    return packageData;
  } else {
    return {
      applyCount: 0,
      categories: [],
      contentsImageUrl: null,
      description: null,
      discountRate: 0,
      endDate: '',
      events: [],
      pickPoints: [],
      id: '',
      name: '',
      startDate: '',
    };
  }
}

export default function usePackage(packageId) {
  const dispatch = useDispatch();
  const packageData = useSelector((state) => state.package);

  const option = {
    variables: {
      id: packageId,
    },
  };

  const { loading } = useQuery(GetEventPackage, option);

  const onSelectEvent = (eventId) => () => {
    pixelEvent('AddToCart');
    dispatch(selectEvent({ eventId, packageId }));
  };

  return {
    ...setInitialModel(packageData[packageId]),
    onSelectEvent,
    loading,
  };
}
