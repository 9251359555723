import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import * as Styled from './index.styles';
import { createUrlWithQuery } from 'utils/createUrlWithQuery';

export default function Confirm() {
  const router = useRouter();

  const pickUrl = createUrlWithQuery({ query: router.query, url: '/pick' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Styled.Container>
      <Styled.Title>상담이 신청되었습니다.</Styled.Title>
      <Styled.Content>상담 및 내원 안내를 위해 병원에서</Styled.Content>
      <Styled.Content>확인 후 연락드릴 예정이오니</Styled.Content>
      <Styled.Content split>잠시만 기다려주세요!</Styled.Content>
      <Styled.Content>주말이나 야간에 상담을 신청하는 경우</Styled.Content>
      <Styled.Content>병원의 연락이 다소 늦어질 수 있으니</Styled.Content>
      <Styled.Content>양해 부탁드려요!</Styled.Content>
      <Styled.Button onClick={() => router.replace(pickUrl)}>확인</Styled.Button>
    </Styled.Container>
  );
}
